import React from 'react';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import './HomeTrial.scss';
import Loader from '../../../Components/Loader';
import Notification from '../../../Components/Notification';
import * as UserAction from '../../../Store/Actions/User';
import { useGameReducer } from '../../../Store';

const HomeTrial = () => {
  const gameReducer = useGameReducer();

  return (
    <>
      <Notification />
      <Outlet />
      {gameReducer.loading && <Loader />}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    userAction: bindActionCreators(UserAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(HomeTrial);
