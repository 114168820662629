import React from 'react';

import './GamePicker.scss';
import { GameType } from '../../Utils';

interface Props {
  gameType: GameType;
  onClick?: () => void;
}

export const GameCard = (props: Props) => {

  const getImg = () => {
    switch (props.gameType) {
      case GameType.Baccarat: return '/img/baccarat.svg';
      case GameType.SicBo: return '/img/sicbo.svg';
      case GameType.Roulette: return '/img/roulette.svg';
    }
  };

  const getClass = () => {
    switch (props.gameType) {
      case GameType.Baccarat: return 'baccarat';
      case GameType.SicBo: return 'sicbo';
      case GameType.Roulette: return 'roulette';
    }
  };

  const getAltName = () => {
    switch (props.gameType) {
      case GameType.Baccarat: return '百家乐';
      case GameType.SicBo: return '骰宝';
      case GameType.Roulette: return '轮盘';
    }
  };

  const getName = () => {
    switch (props.gameType) {
      case GameType.Baccarat: return 'Baccarat';
      case GameType.Roulette: return 'Roulette';
      case GameType.SicBo: return 'Sic Bo';
    }
  };

  return (
    <div
      className={'select_game_card ' + getClass()}
      onClick={props.onClick}
    >
      <img src={getImg()} alt='game-img'/>
      <div className='game_name'>
        <span className='game_text_alt'>{getAltName()}</span>
        <span className='game_text'>{getName()}</span>
      </div>
    </div>
  );
};
