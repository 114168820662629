import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Header from '../../../Components/Header';
import { FooterMenu } from '../../../Components/FooterMenu';
import * as UserAction from '../../../Store/Actions/User';
import { MessageNew } from '../../../Components/Admin/MessageNew';

const ManagerMessageNew = (props) => {
  const { t } = useTranslation();

  return (
    <div className='agent_layout'>
      <Header name={t('messages.new')} />
      <div className='agent_body'>
        <MessageNew actions={props.actions} />
      </div>

      <FooterMenu isManager={true}/>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    userAction: bindActionCreators(UserAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(ManagerMessageNew);
