import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import './HomeApp.scss';
import Loader from '../../../Components/Loader';
import Notification from '../../../Components/Notification';
import * as UserAction from '../../../Store/Actions/User';
import { useGameReducer } from '../../../Store';

const HomeApp = (props) => {
  const navigate = useNavigate();

  const gameReducer = useGameReducer();

  useEffect(() => {
    if (gameReducer.token) {
      props.actions.userAction.getUserDetails();
    } else {
      navigate('/Login');
    }
  }, [gameReducer.token]);

  return (
    <>
      <Notification />
      <Outlet />
      {gameReducer.loading && <Loader />}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    userAction: bindActionCreators(UserAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(HomeApp);
