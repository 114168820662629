import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Header from '../../../../Components/Header';
import * as UserAction from '../../../../Store/Actions/User';
import { UserDetail } from '../../../../Components/Admin/Users';
import { FooterMenu } from '../../../../Components/FooterMenu';

const AgentDetail = () => {
  return (
    <div className='agent_layout'>
      <Header name={'Agent Profile'} agentMenu={true} backToUrl={'/Manager/Users'} />
      <UserDetail isAgent={true} />
      <FooterMenu isManager={true}/>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    userAction: bindActionCreators(UserAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(AgentDetail);
