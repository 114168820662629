import React, { useEffect, useState } from 'react';

import './ChipsOnField.scss';
import { intToString } from '../../Utils';

const ChipsOnField = (props) => {
  const [bounce, setBounce] = useState(false);

  useEffect(() => {
    setBounce(true);
    setTimeout(() => {
      setBounce(false);
    }, 500);
  }, [props.data.chip]);

  return (
    <>
      {props.data.activeChip !== null && (
        <div className={'select_bet bet_pending ' + (props.large ? 'large-chip' : 'normal-chip')}>
          {intToString(props.data.activeChip)}
        </div>
      )}
      {props.data.chip !== null && (
        <div className={'select_bet bet_confirm ' + (props.large ? 'large-chip' : 'normal-chip')
          + ' ' + (props.data.isWinner ? 'bet_winner' : '')
          + ' ' + (props.data.activeChip !== null ? 'has_pending' : '')
          + ' ' + (bounce ? 'bounce' : '') }>
          {intToString(props.data.chip)}
        </div>
      )}</>
  );
};

export default ChipsOnField;
