import React from 'react';
import { useTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Header from '../../../Components/Header';
import './AgentMessages.scss';
import { FooterMenu } from '../../../Components/FooterMenu';
import * as UserAction from '../../../Store/Actions/User';
import { MessageNew } from '../../../Components/Admin/MessageNew';

const AgentMessageDetails = (props) => {
  const { t } = useTranslation();

  return (
    <div className='agent_layout new_'>
      <Header name={t('messages.new')} agentMenu={true} />
      <div className='agent_body'>
        <MessageNew actions={props.actions} />
      </div>
      <FooterMenu />
    </div>
  );
};


const mapDispatchToProps = (dispatch) => ({
  actions: {
    userAction: bindActionCreators(UserAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(AgentMessageDetails);
