import React from 'react';
import './GameClosedLoader.scss';

export const GameClosedLoader = (props) => {
  return (
    <div className='dialog loadingPage_counter'>
      <div className="message zh">
        当前的投注期结束<br/>
        新投注期即将开始
      </div>
      <div className="message en">
        Current Game Closed<br/>
        New Game opening soon
      </div>
      <div className='counter'>
        { props.minutes >= 0 ? (
          <>
            <span className='number'>{props.minutes}</span>
          </>
        ) : (
          <>
            <span className='small'>Failed to open New Game. Retry in { 5- (props.minutes%5*-1) }</span>
          </>
        )}
      </div>
    </div>
  );
};
