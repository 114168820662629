export const IN_PROGRESS = 'IN_PROGRESS';
export const COMPLETE = 'COMPLETE';
export const IS_AUTHENTICATED = 'IS_AUTHENTICATED';
export const ERROR = 'ERROR';
export const LOGOUT = 'LOGOUT';
export const GET_USER = 'GET_USER';
export const GET_USER_BALANCE_MESSAGES = 'GET_USER_BALANCE_MESSAGES';
export const INIT_TRIAL_BALANCE = 'INIT_TRIAL_BALANCE';
export const CHANGE_TRIAL_BALANCE = 'CHANGE_TRIAL_BALANCE';
export const RESET_GAME = 'RESET_GAME';
export const GET_GAME_TIME = 'GET_GAME_TIME';
export const GET_PAST_RESULTS = 'GET_PAST_RESULTS';
export const GET_USERS = 'GET_USERS';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const NEW_USER = 'NEW_USER';
export const SUCCESS_MESSAGE = 'SUCCESS_MESSAGE';
export const RESET_USER_TRANSACTION = 'RESET_USER_TRANSACTION';
export const GET_USER_TRANSACTION = 'GET_USER_TRANSACTION';
export const GET_USER_BETS = 'GET_USER_BETS';
export const GET_MESSAGES = 'GET_MESSAGES';
export const ERROR_PAGE = 'ERROR_PAGE';
export const DISABLE_BET = 'DISABLE_BET';
export const SET_STEP = 'SET_STEP';
export const GET_GAME_STATISTICS = 'GET_GAME_STATISTICS';
export const GET_TOP_BETS = 'GET_TOP_BETS';
export const GET_TOP_WINNERS = 'GET_TOP_WINNERS';

// Roulette
export const ROULETTE_BET_SELECTION = 'ROULETTE_BET_SELECTION';
export const ROULETTE_CLEAR_BET_SELECTION = 'ROULETTE_CLEAR_BET_SELECTION';
export const ROULETTE_APPLY_CHIP = 'ROULETTE_APPLY_CHIP';
export const ROULETTE_CONFIRMING_BET = 'ROULETTE_CONFIRMING_BET';
export const ROULETTE_BETS_BY_SESSION = 'ROULETTE_BETS_BY_SESSION';
export const ROULETTE_RESULT = 'ROULETTE_RESULT';
export const ROULETTE_SHOW_RESULT_AREA = 'ROULETTE_SHOW_RESULT_AREA';
export const ROULETTE_SHOW_WINNING_AREA = 'ROULETTE_SHOW_WINNING_AREA';

export const SICBO_BET_SELECTION = 'SICBO_BET_SELECTION';
export const SICBO_CLEAR_BET_SELECTION = 'SICBO_CLEAR_BET_SELECTION';
export const SICBO_APPLY_CHIP = 'SICBO_APPLY_CHIP';
export const SICBO_CONFIRMING_BET = 'SICBO_CONFIRMING_BET';
export const SICBO_BETS_BY_SESSION = 'SICBO_BETS_BY_SESSION';
export const SICBO_RESULT = 'SICBO_RESULT';
export const SICBO_SHOW_RESULT_AREA = 'SICBO_SHOW_RESULT_AREA';
export const SICBO_SHOW_WINNING_AREA = 'SICBO_SHOW_WINNING_AREA';

export const BACCARAT_BET_SELECTION = 'BACCARAT_BET_SELECTION';
export const BACCARAT_APPLY_CHIP = 'BACCARAT_APPLY_CHIP';
export const BACCARAT_CONFIRMING_BET = 'BACCARAT_CONFIRMING_BET';
export const BACCARAT_CLEAR_BET_SELECTION = 'BACCARAT_CLEAR_BET_SELECTION';
export const BACCARAT_BETS_BY_SESSION = 'BACCARAT_BETS_BY_SESSION';
export const BACCARAT_RESULT = 'BACCARAT_RESULT';
export const BACCARAT_SHOW_RESULT_AREA = 'BACCARAT_SHOW_RESULT_AREA';
export const BACCARAT_SHOW_WINNING_AREA = 'BACCARAT_SHOW_WINNING_AREA';

export const ALL_RESULT = 'ALL_RESULT';
export const ALL_SHOW_RESULT_AREA = 'ALL_SHOW_RESULT_AREA';
export const ALL_SHOW_WINNING_AREA = 'ALL_SHOW_WINNING_AREA';
