import React from 'react';

import './TopBets.scss';
import { useAutoScroll } from '../useAutoScroll';
import { useGameReducer } from '../../../Store';

interface Props {
  topBets: any[];
}

const TopBetsDialog = (props: Props) => {
  useAutoScroll('list_auto_scroll');

  return (
    <div className='top_bet_list horizontal_list_dialog top_dialog_box'>
      <div className='list_inner list_title'>
        <div className="icon"></div>
      </div>
      <div className='list_main list_auto_scroll'>
        {props.topBets.map((e, i) => {
          return (
            <div key={i} className='list_inner'>
              <div className='players_amount'>
                <span className='player'>{ e.playerDisplayName }</span>
              </div>
              <div className='players_amount'>
                <span className='amount'>{ e.betAmount }</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};


const TopBets = () => {
  const reducer = useGameReducer();

  return (
    <>
      {reducer.topBets && reducer.topBets.length > 0 && (
        <TopBetsDialog topBets={reducer.topBets} />
      )}
    </>
  );
};

export default TopBets;
