import axios, { AxiosRequestConfig } from 'axios';
import jwt_decode from 'jwt-decode';
import sign from 'jwt-encode';
import i18n from 'i18next';

import { ERROR_PAGE, LOGOUT } from '../Constants';
import { useEffect, useState } from 'react';
import ENVIRONMENT_VARIABLES from '../environment.config';

export * from './audio.utils';
export * from './chip.utils';

const secret = 'LuckMinutes2022!';

export interface UserDetail {
  created: string;
  details: string;
  displayName: string;
  id: string;
  labels: string[];
  name: string;
  role: number;
}

export const getAccessToken = () => {
  return localStorage.getItem('accessToken');
};

export const clearAccessToken = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('userDetail');
  localStorage.clear();
};

export const setAccessToken = (token: string) => {
  localStorage.setItem('accessToken', token);
};

export const setUserDetail = (userDetail: UserDetail) => {
  const jwt = sign(userDetail, secret);
  localStorage.setItem('userDetail', jwt);
};

export const getUserDetail = (): UserDetail => {
  const jwt = localStorage.getItem('userDetail');
  if (jwt && jwt !== '') {
    return jwt_decode(jwt);
  }
  return null;
};

export const roundBalance = (balance: number | string) => Math.floor(typeof balance === 'string' ? parseFloat(balance) : balance);

export const isLoggedIn = () => {
  const accessToken = getAccessToken();
  return !!accessToken;
};

export const isLoggedInWithRole = (Role) => {
  const accessToken = getAccessToken();
  const userDetail = getUserDetail();
  return accessToken && (userDetail as any)?.role === Role;
};

export const vibrate = (time: number) => {
  if (navigator && typeof navigator.vibrate === 'function') {
    navigator.vibrate(time || 100);
  }
};

export const retryRequest = async (
  api,
  errorMessage = 'Can\'t fetch data from server',
  count = 1,
  delay = 5000
) => {
  try {
    if (count >= 0) {
      const response = await axios(api);
      if (!response.data) {
        await new Promise(resolve => setTimeout(resolve, delay));
        return await retryRequest(api, errorMessage, --count);
      }
      return response;
    } else {
      throw Error(errorMessage);
    }
  } catch (error) {
    if (count >= 0) {
      await new Promise(resolve => setTimeout(resolve, delay));
      return await retryRequest(api, errorMessage, --count);
    } else {
      throw Error(errorMessage);
    }
  }
};

export const requestError = (
  dispatch: (_) => void,
  reject: (c: string) => void,
  defaultError?: string,
  errorActionType?: string
) => (error) => {
  const callReject = (message: string) => {
    if (typeof reject === 'function') {
      reject(message);
    }
  };
  if (typeof dispatch !== 'function') {
    dispatch = () => null;
  }
  if (error && error.response) {
    if (error.response.status === 403) {
      clearAccessToken();
      dispatch({
        type: LOGOUT,
      });
      callReject('error.permissionDenied');
    } else {
      dispatch({
        type: errorActionType || ERROR_PAGE,
        data: { error_msg: defaultError || error.response.data?.message },
      });
      callReject(defaultError || error.response.data?.message);
    }
  } else {
    dispatch({
      type: errorActionType || ERROR_PAGE,
      data: { error_msg: defaultError || error.message },
    });
    callReject(defaultError || error.message);
  }
};

export const UserType = {
  PLAYER: 5,
  AGENT: 4,
  MANAGER: 3,
};

export const intToString = (num) => {
  num = num.toString().replace(/[^0-9.]/g, '');
  if (num < 1000) {
    return num;
  }
  const si = [
    { v: 1E3, s: i18n.t('chip.k') },
    { v: 1E6, s: i18n.t('chip.m') },
    { v: 1E9, s: i18n.t('chip.b') },
    { v: 1E12, s: 'T' },
    { v: 1E15, s: 'P' },
    { v: 1E18, s: 'E' }
  ];
  let index;
  for (index = si.length - 1; index > 0; index--) {
    if (num >= si[index].v) {
      break;
    }
  }
  return (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[index].s;
};

export enum GameType {
  Roulette = 'roulette_1',
  Baccarat = 'baccarat_1',
  SicBo = 'sicbo_1',
}

/**
 * Detect browser tab is on focus
 * Value is null by default, on any change value set to TRUE of FALSE
 */
export const useVisibleChange = () => {
  const [visible, setVisible] = useState<boolean>();

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);// cleanup this component
    return () => {
      window.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const handleVisibilityChange = () => {
    setVisible(!document.hidden);
  };

  return visible;
};

export const useOnline = () => {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(window.navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener('online', handleStatusChange);

    // Listen to the offline status
    window.addEventListener('offline', handleStatusChange);

    // Specify how to clean up after this effect for performance improvement
    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, [isOnline]);

  return isOnline;
};

export const getRequestConfig = (url: string, data?: any, method = 'GET'): AxiosRequestConfig => {
  const token = getAccessToken();
  return {
    method,
    headers: { Authorization: token },
    url: ENVIRONMENT_VARIABLES.Base_API_URL + url,
    ...(data ? {data} : {})
  };
};
