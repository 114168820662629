import React, { useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { t } from 'i18next';

import './BaccaratStatistic.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as GameActions from '../../../Store/Actions/game.actions';
import { BaccaratStatistics, useGameReducer } from '../../../Store';

interface Props {
  actions: {
    game: any;
  }
}

const BaccaratStatistic = (props: Props) => {
  const gameReducer = useGameReducer();
  const [stats, setStats] = useState<BaccaratStatistics>(null);
  const [form, setForm] = useState([]);

  useEffect(() => {
    if (gameReducer.session.id !== gameReducer.statistics.sessionId) {
      props.actions.game.getGameStatistics();
    }
  }, []);


  useEffect(() => {
    if (gameReducer.statistics.baccarat) {
      const getName = code => {
        switch (code) {
          case 'p': return 'player';
          case 'b': return 'banker';
          case 't': return 'tie';
          default: return '';
        }
      };

      setForm(gameReducer.statistics.baccarat.history
        .map(s => ({size: getName(s.values[0]), length: s.values.length}))
        .slice(0, 10)
      );

      setStats(gameReducer.statistics.baccarat);
    }
  }, [gameReducer.statistics.sicbo]);

  const getWinnerChar = (result) => {
    switch (result) {
      case 'banker': return t('baccaratResult.banker.char');
      case 'player': return t('baccaratResult.player.char');
      case 'tie': return t('baccaratResult.tie.char');
      default: return '';
    }
  };

  return (
    <div className='baccarat_statistic'>
      <div className='stat_row result_form'>
        {[...form].reverse().map((col, index) => (
          <div className={'col ' + col.size} key={`col-${index}`}>
            {new Array(col.length > 4 ? 3 : col.length).fill(null).map((_, i) => (
              <span key={i}>{getWinnerChar(col.size)}</span>))}
            {col.length > 4 && (<span>+{col.length - 3}</span>)}
          </div>
        ))}
        <div className={'col ' + form[0]?.size}>
          { form && form[0] && (
            <span>{getWinnerChar(form[0].size)}</span>
          )}
        </div>
      </div>

      <div className='stat_row statistic_boxes'>
        <div className='col-box w-50 player'>
          <div className='col-name'>闲对 Player Pair</div>
          <div className='col-stat'>{stats?.playerPair}</div>
        </div>
        <div className='col-box w-50 banker'>
          <div className='col-name'>庄对 Banker Pair</div>
          <div className='col-stat'>{stats?.bankerPair}</div>
        </div>
      </div>
      <div className='stat_row statistic_boxes'>
        <div className='col-box w-33 player'>
          <div className='col-name'>闲 Player</div>
          <div className='col-stat'>{stats?.player}</div>
        </div>
        <div className='col-box w-33 tie'>
          <div className='col-name'>和 Tie</div>
          <div className='col-stat'>{stats?.tie}</div>
        </div>
        <div className='col-box w-33 banker'>
          <div className='col-name'>庄 Banker</div>
          <div className='col-stat'>{stats?.banker}</div>
        </div>
      </div>
      <div className='stat_row progress_stat'>
        <div className='label'>
          <div>闲 Player</div>
          <div>赔出 Payout</div>
        </div>
        <ProgressBar>
          <ProgressBar
            striped
            className='progressbar_white left'
            now={stats ? stats.playerFavour * 100 : 50}
            key={1}
            label={stats ? `${Math.round(stats.playerFavour * 100)}%` : ''}
          />
          <ProgressBar
            striped
            className='progressbar_red right'
            now={stats ? stats.bankerFavour * 100 : 50}
            key={3}
            label={stats ? `${Math.round(stats.bankerFavour * 100)}%` : ''}
          />
        </ProgressBar>
        <div className='label banker'>
          <div>庄 Banker</div>
          <div>赔出 Payout</div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    game: bindActionCreators(GameActions, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(BaccaratStatistic);
