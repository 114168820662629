import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Header from '../../../../Components/Header';
import * as UserAction from '../../../../Store/Actions/User';
import { UserList } from '../../../../Components/Admin/Users';
import { FooterMenu } from '../../../../Components/FooterMenu';

const ManagerUsers = (props) => {
  return (
    <div className='agent_layout'>
      <Header name={'Agents'} agentMenu={true} backToHome={true} />
      <UserList actions={props.actions} />
      <FooterMenu isManager={true}/>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    userAction: bindActionCreators(UserAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(ManagerUsers);
