import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment-timezone';

import './WebsiteGames.scss';
import { WebsitePageHeader } from '../WebsitePageHeader';
import { WebsiteGameHeader } from '../WebsiteGameHeader';
import { GameType } from '../../../Utils';
import BaccaratBetting from '../../Baccarat/BaccaratBetting';
import SicboBetting from '../../SicBo/SicboBetting';
import BettingFrame from '../../Roulette/BettingFrame/BetFrames';
import { GameReducerState } from '../../../Store';
import { TrialViewModes } from '../TrialViewModes';
import { StepId } from '../../../Constants/gameSteps';
import { GameCard } from '../../GamePicker';

interface Props {
  gameReducer: GameReducerState,
  gameActions: {
    game: any;
    user: any;
  };
  minutes: number;
}

const stepTime = 5000;

export const WebsiteGames = (props: Props) => {
  const scrollToRef = useRef(null);
  const scrollAreaRef = useRef(null);
  const nextSubview = useRef(TrialViewModes.STAT);
  const [trialViewMode, setTrialViewMode] = useState(TrialViewModes.LATEST);
  const updateNotificationRef = useRef();

  useEffect(() => {
    setTrialViewMode(TrialViewModes.LATEST);
  }, [props.gameReducer.stepId]);

  useEffect(() => {
    let timeout;
    switch (trialViewMode) {
      case TrialViewModes.LATEST:
        if (props.gameReducer.stepId === StepId.LATEST_RESULT) {
          timeout = setTimeout(async () => {
            if (nextSubview.current === TrialViewModes.STAT && props.gameReducer.session.id !== props.gameReducer.statistics.sessionId) {
              await props.gameActions.game.getGameStatistics();
            }
            if (nextSubview.current === TrialViewModes.RESULTS && (!props.gameReducer.pastResults[0] || moment(props.gameReducer.session.startedAt).diff(props.gameReducer.pastResults[0].startedAt, 'ms') !== 0)) {
              await props.gameActions.user.getPastResults();
            }
            setTrialViewMode(() => nextSubview.current);
          }, stepTime * 2);
        }
        break;
      case TrialViewModes.RESULTS:
        nextSubview.current = TrialViewModes.STAT;
        timeout = setTimeout(() => { setTrialViewMode(() => TrialViewModes.LATEST); }, stepTime);
        break;
      case TrialViewModes.STAT:
        nextSubview.current = TrialViewModes.RESULTS;
        timeout = setTimeout(() => { setTrialViewMode(() => TrialViewModes.LATEST); }, stepTime);
        break;
    }
    return () => clearTimeout(timeout);
  }, [trialViewMode, props.gameReducer.stepId]);

  useEffect(() => {
    // @ts-ignore
    updateNotificationRef?.current?.animate(
      {
        marginTop: ['40px', 0, 0, 0, '-40px'],
        opacity: [0, 1, 1, 1, 0]
      },
      1010
    );
  }, [props.minutes]);

  useEffect(() => {
    const timer1 = setTimeout(() => { scrollAreaRef.current.scrollTop = scrollToRef.current.offsetTop; }, 500);
    const timer2 = setTimeout(() => { scrollAreaRef.current.scrollTop = scrollToRef.current.offsetTop; }, 1750);
    const timer3 = setTimeout(() => { scrollAreaRef.current.scrollTop = scrollToRef.current.offsetTop; }, 3000);
    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
    };
  }, []);

  return (
    <div className='all_games'>
      <WebsitePageHeader
        minutes={props.minutes}
        startTime={props.gameReducer.session?.startTime}
        resultTime={props.gameReducer.lastSession?.startTime}
        trialViewMode={trialViewMode}
        stepId={props.gameReducer.stepId}
      />

      <div className="all_games_container" ref={scrollAreaRef}>
        <div className="scroll_to" id='scroll_to' ref={scrollToRef}></div>
        <div className="game_box_container baccarat">
          <GameCard gameType={GameType.Baccarat}/>
          <div className="game_box">
            <WebsiteGameHeader gameType={GameType.Baccarat} viewMode={trialViewMode}/>
            <BaccaratBetting/>
          </div>
        </div>
        <div className="game_box_container sicbo">
          <GameCard gameType={GameType.SicBo}/>
          <div className="game_box">
            <WebsiteGameHeader gameType={GameType.SicBo} viewMode={trialViewMode}/>
            <SicboBetting/>
          </div>
        </div>
        <div className="game_box_container roulette">
          <GameCard gameType={GameType.Roulette}/>
          <div className="game_box">
            <WebsiteGameHeader gameType={GameType.Roulette} viewMode={trialViewMode}/>
            <BettingFrame/>
          </div>
        </div>
      </div>
    </div>
  );
};
