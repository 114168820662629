import React from 'react';
import './LuckyMinute.scss';

interface Props {
  large?: boolean;
}

export const Play60s = (props: Props) => {
  return (
    <div className={'play60s' + (props.large ? ' large' : '')}>
      <span className="first">娱乐<span>60秒</span></span>
      <span className="second">Play<span>60s</span></span>
    </div>
  );
};
