import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import './WebsiteGameHeader.scss';
import { GameType } from '../../../Utils';
import BaccaratStatistic from '../../Baccarat/BaccaratStatistic';
import RouletteStatistic from '../../Roulette/RouletteStatistic';
import SicBoStatistic from '../../SicBo/SicBoStatistic';
import GamePastResults from '../../GamePastResults';
import { TRIAL_BASE_PATH } from '../../../Constants';
import { TrialViewModes } from '../TrialViewModes';

interface Props {
  viewMode: TrialViewModes,
  gameType: GameType;
}

export const WebsiteGameHeader = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const getGameStatistics = (gameType: GameType) => {
    switch (gameType) {
      case GameType.Baccarat: return <BaccaratStatistic />;
      case GameType.Roulette: return <RouletteStatistic />;
      case GameType.SicBo: return <SicBoStatistic />;
    }
  };

  const getTrialRoute = (gameType: GameType) => {
    switch (gameType) {
      case GameType.Baccarat: return `${TRIAL_BASE_PATH}/Baccarat`;
      case GameType.SicBo: return `${TRIAL_BASE_PATH}/Sicbo`;
      case GameType.Roulette: return `${TRIAL_BASE_PATH}/Roulette`;
    }
  };

  return (
    <>
      {props.viewMode === TrialViewModes.RESULTS && (
        <div className="dialog website">
          <GamePastResults gameType={props.gameType} />
        </div>
      )}
      {props.viewMode === TrialViewModes.STAT && (
        <div className="dialog website">
          {getGameStatistics(props.gameType)}
        </div>
      )}
      <div className="game_header">
        <div className="game_title">{t(`gameType.${props.gameType}.mixedName`)}</div>
        <div className="game_play_button" onClick={() => navigate(getTrialRoute(props.gameType))}>{t('trial.playNow')}</div>
      </div>
    </>
  );
};
