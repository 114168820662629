export const ROULETTE = [
  {
    value: '3',
    name: '3',
    chip: null,
    isActive: false,
    index: 1,
    color: 'red',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'field_3',
  },
  {
    value: '6',
    name: '6',
    chip: null,
    isActive: false,
    index: 1,
    color: 'black',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'field_6',
  },
  {
    value: '9',
    name: '9',
    chip: null,
    isActive: false,
    index: 1,
    color: 'red',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'field_9',
  },
  {
    value: '12',
    name: '12',
    chip: null,
    isActive: false,
    index: 1,
    color: 'red',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'field_12',
  },
  {
    value: '15',
    name: '15',
    chip: null,
    isActive: false,
    index: 1,
    color: 'black',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'field_15',
  },
  {
    value: '18',
    name: '18',
    chip: null,
    isActive: false,
    index: 1,
    color: 'red',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'field_18',
  },
  {
    value: '2',
    name: '2',
    chip: null,
    isActive: false,
    index: 2,
    color: 'black',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'field_2',
  },
  {
    value: '5',
    name: '5',
    chip: null,
    isActive: false,
    index: 2,
    color: 'red',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'field_5',
  },
  {
    value: '8',
    name: '8',
    chip: null,
    isActive: false,
    index: 2,
    color: 'black',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'field_8',
  },
  {
    value: '11',
    name: '11',
    chip: null,
    isActive: false,
    index: 2,
    color: 'black',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'field_11',
  },
  {
    value: '14',
    name: '14',
    chip: null,
    isActive: false,
    index: 2,
    color: 'red',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'field_14',
  },
  {
    value: '17',
    name: '17',
    chip: null,
    isActive: false,
    index: 2,
    color: 'black',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'field_17',
  },
  {
    value: '1',
    name: '1',
    chip: null,
    isActive: false,
    index: 3,
    color: 'red',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'field_1',
  },
  {
    value: '4',
    name: '4',
    chip: null,
    isActive: false,
    index: 3,
    color: 'black',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'field_4',
  },
  {
    value: '7',
    name: '7',
    chip: null,
    isActive: false,
    index: 3,
    color: 'red',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'field_7',
  },
  {
    value: '10',
    name: '10',
    chip: null,
    isActive: false,
    index: 3,
    color: 'black',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'field_10',
  },
  {
    value: '13',
    name: '13',
    chip: null,
    isActive: false,
    index: 3,
    color: 'black',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'field_13',
  },
  {
    value: '16',
    name: '16',
    chip: null,
    isActive: false,
    index: 3,
    color: 'red',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'field_16',
  },
  {
    value: '1-9',
    name: '1-9',
    chip: null,
    isActive: false,
    index: 4,
    isConfirm: false,
    activeChip: null,
    backEndValue: 'low',
  },
  {
    value: '0',
    name: '0',
    chip: null,
    isActive: false,
    index: 4,
    color: 'green',
    className: 'bet_two_box1',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'field_0',
  },
  {
    value: '10-18',
    name: '10-18',
    chip: null,
    isActive: false,
    index: 4,
    isConfirm: false,
    activeChip: null,
    backEndValue: 'high',
  },
  {
    value: 'even',
    name: 'roulette.even',
    english: 'Even',
    chinese: '双',
    chip: null,
    isActive: false,
    index: 5,
    isConfirm: false,
    activeChip: null,
    backEndValue: 'even',
  },
  {
    value: 'red',
    name: 'roulette.red',
    english: 'Red',
    chip: null,
    isActive: false,
    index: 5,
    image: '/img/red_diamond.svg',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'red',
  },
  {
    value: 'black',
    name: 'roulette.black',
    english: 'Black',
    chip: null,
    isActive: false,
    index: 5,
    image: '/img/black_diamond.svg',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'black',
  },
  {
    value: 'odd',
    name: 'roulette.odd',
    english: 'Odd',
    chinese: '单',
    chip: null,
    isActive: false,
    index: 5,
    isConfirm: false,
    activeChip: null,
    backEndValue: 'odd',
  },
];
