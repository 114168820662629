/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as GameActions from '../../../Store/Actions/game.actions';
import { SicboDiceDialog } from '../SicboDiceDialog';
import './SicboBetting.scss';
import ChipsOnField from '../../ChipsOnField';
import { GameType } from '../../../Utils';
import { useGameReducer } from '../../../Store';
import { StepId } from '../../../Constants/gameSteps';

const SicboBetting = (props) => {
  const [row, setRow] = useState([]);
  const gameReducer = useGameReducer();

  useEffect(() => {
    if (gameReducer.sicbo) {
      setRow(gameReducer.sicbo);
    }
  }, [gameReducer.sicbo]);

  const handleClickRow = (selectedBet) => {
    if (!gameReducer.disableBet) {
      props.actions.game.betSelection(GameType.SicBo, selectedBet, gameReducer.selectedChip);
    }
  };

  return (
    <>
      <div className='main_layout game_layout'>
        <div className='main_layout_inner'>
          <div className='sicbobetting'>
            <div className='sicbo_row_one'>
              {row.map(
                (data, index) =>
                  data.index === 1 && (
                    <span
                      key={index}
                      className={`row_one_inner ${
                        data.isActive ? 'selected-bet' : ''
                      }`}
                      onClick={() => handleClickRow(data)}
                    >
                      {data.image ? (
                        <img src={data.image} alt='dice' />
                      ) : (
                        <>
                          <span className='ch'>{ data.chinese }</span>
                          <span className='f'>{ data.value }</span>
                        </>
                      )}

                      <ChipsOnField large={true} data={data}></ChipsOnField>
                    </span>
                  )
              )}
            </div>

            <div className='sicbo_row_second'>
              {row.map(
                (data, index) =>
                  data.index === 2 && (
                    <div
                      key={index}
                      className={`row_second_inner ${
                        data.isActive ? 'selected-bet' : ''
                      }`}
                      onClick={() => handleClickRow(data)}
                    >
                      {data.image ? (
                        <img src={data.image} alt='dice' />
                      ) : (
                        data.value
                      )}

                      <ChipsOnField data={data}></ChipsOnField>
                    </div>
                  )
              )}
            </div>

            <div className='sicbo_row_third'>
              {[3, 4].map((rowData, i) => (
                <div key={i} className='row_third_inner'>
                  {row.map((data, index) => (
                    <React.Fragment key={index}>
                      {data.index === rowData && (
                        <div
                          key={index}
                          onClick={() => handleClickRow(data)}
                          className={`sicbo_bet ${
                            data.className ? data.className : ''
                          } ${data.isActive ? 'selected-bet' : ''}`}
                        >
                          {data.value}

                          <ChipsOnField data={data}></ChipsOnField>
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              ))}
            </div>

            <div className='sicbo_row_fourth'>
              {row.map(
                (data, index) =>
                  data.index === 5 && (
                    <div
                      key={index}
                      className={`row_fourth_inner ${
                        data.isActive ? 'selected-bet' : ''
                      }`}
                      onClick={() => handleClickRow(data)}
                    >
                      {data.image ? (
                        <img src={data.image} alt='dice' />
                      ) : (
                        data.value
                      )}

                      <ChipsOnField data={data}></ChipsOnField>
                    </div>
                  )
              )}
            </div>
          </div>
        </div>
      </div>
      {gameReducer.sicboResult && gameReducer.stepId === StepId.RESULT && (
        <SicboDiceDialog />
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    game: bindActionCreators(GameActions, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(SicboBetting);
