import React, { useEffect } from 'react';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

import './BetHistory.scss';
import { useGameReducer } from '../../Store';
import { GameType } from '../../Utils';
import { BACCARAT, ROULETTE, SICBO } from '../../Constants';
import Loader from '../Loader';
import { DATETIME_FORMAT } from '../../environment.config';

interface Props {
  actions: any;
  userId?: string;
}

export const BetHistory = (props: Props) => {
  const { t } = useTranslation();
  useEffect(() => {
    props.actions.userAction.getUserBets(null, props.userId);
  }, []);

  const reducer = useGameReducer();

  const fetchBets = () => {
    props.actions.userAction.getUserBets(reducer.betHistory[reducer.betHistory.length - 1].created, props.userId);
  };

  const getFieldName = (gameType, fieldName) => {
    switch (gameType) {
      case GameType.Baccarat:
        return t(BACCARAT.find(f => f.backEndValue === fieldName)?.name);
      case GameType.Roulette:
        return t(ROULETTE.find(f => f.backEndValue === fieldName)?.name);
      case GameType.SicBo:
        return t(SICBO.find(f => f.backEndValue === fieldName)?.name);
    }
  };

  return (
    <div className='bet_history_main list'>
      <div className='history_header list-header'>
        <div className='history_header_inner'>
          <span className='header_inner_text'>{t('history.dateTime')}</span>
          <span className='header_inner_text'>{t('history.game')}</span>
        </div>
        <div className='history_header_inner'>
          <span className='header_inner_text1'>{t('history.betType')}</span>
          <span className='header_inner_text1'>{t('history.bet')}</span>
          <span className='header_inner_text1'>{t('history.payout')}</span>
        </div>
      </div>

      <div className='bet_history_body list-body'>
        {reducer.betHistory?.sort((a, b) => a.created > b.created ? -1 : 1).map(transaction => {
          const date = moment(transaction.created);
          const payout = transaction.payout != '<nil>' ? transaction.payout : t('history.unsettled');
          const fieldName = getFieldName(transaction.gameType, transaction.fieldName);
          return (
            <div className='history_body' key={transaction.createTxId}>
              <div className='history_body_inner'>
                <span className='body_inner_text'>{date.format(DATETIME_FORMAT)}</span>
                <span className='body_inner_text'>{t(`gameType.${transaction.gameType}`)}</span>
              </div>
              <div className='history_body_inner'>
                <span className='body_inner_text1'>{fieldName}</span>
                <span className='body_inner_text1'>{transaction.amount}</span>
                <span className='body_inner_text1'>{parseFloat(payout)}</span>
              </div>
            </div>
          );
        })}
        { reducer.loading && <Loader></Loader> }
        { reducer.betHistory.length > 0 && !reducer.loading && reducer.betHistoryHasMore && (
          <button className='more' onClick={fetchBets}>{t('label.loadMore')}</button>
        ) }
      </div>
    </div>
  );
};
