import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './TrialStart.scss';
import * as UserAction from '../../../Store/Actions/User';
import { GamePicker } from '../../../Components/GamePicker';
import Header from '../../../Components/Header';

const TrialStart = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className='trial_home page_container'>
      <Header logo={true} name={t('trial.title')} login={true}></Header>
      <div className='page_content'>
        <GamePicker />
        <button className='secondary' onClick={() => navigate('/')}>{t('trial.backToWebsite')}</button>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    userAction: bindActionCreators(UserAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(TrialStart);
