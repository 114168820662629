import React from 'react';
import './PlayingCards.scss';

const PlayingCards = (props) => {
  return (
    <div className='playing_cards'>
      <div className={`card_icon ${props.suit}`}></div>
      <div className={`numbers ${props.suit}`}>{props.rank}</div>
    </div>
  );
};

export default PlayingCards;
