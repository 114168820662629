import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';

import Header from '../../../../Components/Header';
import { IN_PROGRESS, SUCCESS_MESSAGE, ERROR, LOGOUT } from '../../../../Constants';
import * as UserAction from '../../../../Store/Actions/User';
import { clearAccessToken } from '../../../../Utils';
import { FooterMenu } from '../../../../Components/FooterMenu';
import { CreditWithdraw } from '../../../../Components/Admin/Users/CreditWithdraw';

const AgentWithdrawCredit = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const submit = (payload, callback) => {
    dispatch({ type: IN_PROGRESS });
    UserAction.creditTransfers(payload)
      .then((response) => {
        if (response.data.success) {
          props.actions.userAction.getUserBalanceAndMessages();
          props.actions.userAction.getUsers();
          callback();
          dispatch({
            type: SUCCESS_MESSAGE,
            data: {
              message: t('credit.transferSuccess', {amount: payload.amount}),
            },
          });
          navigate('../Transactions');
        } else {
          dispatch({
            type: ERROR,
            data: { error_msg: response.data.message },
          });
        }
      })
      .catch((error) => {
        if (error && error.response) {
          if (error.response.status === 403) {
            clearAccessToken();
            dispatch({
              type: LOGOUT,
            });
          } else
            dispatch({
              type: ERROR,
              data: { error_msg: error.response.data.message },
            });
        } else {
          dispatch({
            type: ERROR,
            data: { error_msg: error.message.toString() },
          });
        }
      });
  };

  return (
    <div className='agent_layout'>
      <Header name={t('userAction.withdraw')} agentMenu={true} />
      <CreditWithdraw submit={submit} />
      <FooterMenu />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    userAction: bindActionCreators(UserAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(AgentWithdrawCredit);
