import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment-timezone';

import { useGameReducer } from '../../Store';
import { DATETIME_FORMAT } from '../../environment.config';

import './MessageList.scss';

interface Props {
  actions: { userAction: any };
}

export const MessageList = (props: Props) => {
  const { userName } = useParams();
  const [threads, setThreads] = useState([]);
  const navigate = useNavigate();
  const redirect = (url) => {
    navigate(url);
  };

  const gameReducer = useGameReducer();

  useEffect(() => {
    if (gameReducer.messages && gameReducer.users) {
      const threads = [];
      if (gameReducer.operator) {
        threads.push(gameReducer.operator);
      }
      setThreads([
        ...threads.map(u => ({user: u, operator: true})),
        ...gameReducer.users.map(u => ({user: u.user, operator: false}))].map(u => {
        return {
          ...u,
          lastMessage: gameReducer.messages.find(m => m.senderId === u.user.id || m.recipientId == u.user.id)
        };
      }));
    }
  }, [gameReducer.operator, gameReducer.messages, gameReducer.users]);

  useEffect(() => {
    props.actions.userAction.getMessages();
    props.actions.userAction.getUsers();
  }, []);

  return (
    <div className='messages_body'>
      { userName && (
        <div className='header_box'>
          <span className='sub_header_text'>{userName}</span>
        </div>
      )}
      <div className='message_list'>
        {threads?.map((thread, i) => {
          const date = moment(thread.lastMessage?.createdAt);
          return (
            <div className={'message ' + (thread.operator ? 'operator' : '')} onClick={() => redirect(thread.user.id)} key={'message.' + i}>
              <div className='message_meta'>
                <span className='sender'>{thread.user.name}</span>
                <span className='date'>{thread.lastMessage ? date.format(DATETIME_FORMAT) : ''}</span>
              </div>
              <div className='message_body'>
                <span className='body_list'>
                  {thread.lastMessage ? thread.lastMessage.subject : 'No messages'}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
