import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

import { useGameReducer, useUser } from '../../../Store';
import { ERROR } from '../../../Constants';
import { roundBalance, UserType } from '../../../Utils';
import { getUserActiveBets } from '../../../Store/Actions/User';

interface Payload {
  fromUserId: string,
  toUserId: string,
  amount: string,
  reference: 'Withdraw',
}

interface Props {
  submit: (payload: Payload, callback: () => void) => void,
}
export const CreditWithdraw = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useUser(useParams().userId);
  const [loading, setLoading] = useState(true);
  const [pendingBets, setPendingBets] = useState(0);
  const [data, setData] = useState({amount: '0',});
  const { t } = useTranslation();

  const gameReducer = useGameReducer();

  useEffect(() => {
    if (user && !pendingBets) {
      fetchBets();
    }
  }, [user]);

  const fetchBets = () => {
    setLoading(true);
    getUserActiveBets(user.user.id).then(v => {
      setPendingBets(v);
      setLoading(false);
    }).catch(() => {
      setPendingBets(0);
      setLoading(false);
    });
  };

  const submit = () => {
    if (data.amount && data.amount !== '0') {
      props.submit({
        fromUserId: user.user.id,
        toUserId: gameReducer.userDetail.id,
        amount: data.amount,
        reference: 'Withdraw',
      }, () => {
        navigate('../Transactions');
        setData({ amount: '0' });
      });
    } else {
      dispatch({
        type: ERROR,
        data: { error_msg: t('credit.invalidAmount') },
      });
    }
  };

  const handleChange = (event) => {
    const field = event.target.name;
    const commonData = { ...data };
    commonData[field] = event.target.value;
    return setData(commonData);
  };

  const withdrawAll = () => {
    setData({amount: user.balance});
  };

  return (
    <>
      {user && (
        <div className='agent_body'>
          <div className='row_details'>
            <div className='header_box'>
              <span className='sub_header_text'>{user.user.name}</span>
            </div>
            { user.user.role === UserType.PLAYER && (
              <div className='row_details_inner'>
                <div className='row_details_text'>{t('credit.playerBalanceInBet')}</div>
                <div className='row_details_text'>
                  {roundBalance(pendingBets)}
                  <button disabled={loading} className='small' onClick={() => fetchBets()}><i className='fa-solid fa-refresh'></i></button>
                </div>
              </div>
            )}
            <div className='row_details_inner'>
              <div className='row_details_text'>{t('credit.playerBalance')}</div>
              <div className='row_details_text'>
                {roundBalance(user.balance)}
                { user.user.role === UserType.PLAYER && (
                  <button disabled={pendingBets > 0} className='small' onClick={() => withdrawAll()}>Withdraw all</button>
                )}
              </div>
            </div>
            <div className='row_details_inner'>
              <span className='row_details_text'>{t('credit.agentBalance')}</span>
              <span className='row_details_text'>{roundBalance(gameReducer.balance)}</span>
            </div>
          </div>

          <ValidatorForm
            className='input_form_box'
            onSubmit={() => submit()}
            autoComplete='off'
          >
            <div className='input_form_box_inner'>
              <div className='input_form'>
                <span className='input_box_text'>{t('credit.enterWithdraw')}</span>
                <TextValidator
                  validators={['required', 'minNumber:0', ('maxNumber:'+roundBalance(user.balance).toString())]}
                  errorMessages={['this field is required']}
                  variant='outlined'
                  size='small'
                  type='number'
                  className='input_box '
                  value={data.amount}
                  name='amount'
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className='form_btn'>
              <Button type='submit' variant='contained' className='submit_btn'>
                {t('label.submit')}
              </Button>
            </div>
          </ValidatorForm>
        </div>
      )}
    </>
  );
};
