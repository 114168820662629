import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import '../../../Styles/agent-style.scss';
import './HomeManager.scss';
import '@fontsource/inter';
import Loader from '../../../Components/Loader';
import Notification from '../../../Components/Notification';
import * as UserAction from '../../../Store/Actions/User';
import { useGameReducer } from '../../../Store';

const HomeManager = (props) => {
  const navigate = useNavigate();

  const gameReducer = useGameReducer();

  useEffect(() => {
    if (gameReducer.token) {
      props.actions.userAction.getUserDetails();
    } else {
      navigate('/Login');
    }
  }, [gameReducer.token]);

  useEffect(() => {
    if (gameReducer.userDetail) {
      props.actions.userAction.getUserBalanceAndMessages();
    }
  }, [gameReducer.userDetail]);

  return (
    <>
      <Notification />
      <div className='page_container agent_container'>
        <Outlet />
        {gameReducer.loading && <Loader />}
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    userAction: bindActionCreators(UserAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(HomeManager);
