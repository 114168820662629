import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Header from '../../../../Components/Header';
import * as UserAction from '../../../../Store/Actions/User';
import { UserTransactions } from '../../../../Components/Admin/Users';
import { FooterMenu } from '../../../../Components/FooterMenu';

const ManagerTransactions = (props) => {
  const { userId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    props.actions.userAction.getUserTransactions(undefined, undefined, userId);
  }, []);

  return (
    <div className='agent_layout'>
      <Header name={t('userAction.transactions')} />
      <UserTransactions />
      <FooterMenu isManager={true}/>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    userAction: bindActionCreators(UserAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(ManagerTransactions);
