import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Header from '../../../Components/Header';
import * as UserAction from '../../../Store/Actions/User';
import './Transaction.scss';

import { useTranslation } from 'react-i18next';
import { FooterMenu } from '../../../Components/FooterMenu';
import { TransactionList } from '../../../Components/Admin/TransactionList';

const ManagerTransaction = (props) => {
  const { t } = useTranslation();

  return (
    <div className='agent_layout'>
      <Header name={t('menu.transactions')} agentMenu={true} />
      <TransactionList actions={props.actions} />
      <FooterMenu isManager={true}/>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    userAction: bindActionCreators(UserAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(ManagerTransaction);
