import React, { useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import './SicBoStatistic.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as GameActions from '../../../Store/Actions/game.actions';
import { SicboStatistics, useGameReducer } from '../../../Store';

interface Props {
  actions: {
    game: any;
  }
}

const SicBoStatistic = (props: Props) => {
  const gameReducer = useGameReducer();
  const dices = [
    '/img/dice/Dice1.svg',
    '/img/dice/Dice2.svg',
    '/img/dice/Dice3.svg',
    '/img/dice/Dice4.svg',
    '/img/dice/Dice5.svg',
    '/img/dice/Dice6.svg',
  ];
  const [stats, setStats] = useState<SicboStatistics>();
  const [form, setForm] = useState([]);

  useEffect(() => {
    if (gameReducer.session.id !== gameReducer.statistics.sessionId) {
      props.actions.game.getGameStatistics();
    }
  }, []);

  useEffect(() => {
    if (gameReducer.statistics.sicbo) {
      setForm(gameReducer.statistics.sicbo.bigSmallHistory
        .map(s => ({size: getWinnerChar(s.values[0]), length: s.values.length}))
        .slice(0, 10)
      );

      setStats(gameReducer.statistics.sicbo);
    }
  }, [gameReducer.statistics.sicbo]);

  const getWinnerChar = (result) => {
    switch (result) {
      case 's': return '小';
      case 'b': return '大';
      case 't': return '围';
      default: return '';
    }
  };

  return (
    <div className='sicbo_statistic'>
      <div className='stat_row result_form'>
        { form.length > 0 && (
          <>
            { [...form].reverse().map((col, i) => (
              <div className='col' key={'col.' + i}>
                { new Array(col.length > 4 ? 3 : col.length).fill(null).map((_, i) => (<span key={'col_b.'+i}>{col.size}</span>)) }
                { col.length > 4 && (<span>+{col.length - 3}</span>)}
              </div>
            ))}
            { form[0] && (
              <div className='col'>
                <span>{form[0].size}</span>
              </div>
            )}
          </>
        )}
      </div>
      <div className='stat_row progress_stat'>
        <div className='label'>小</div>
        <ProgressBar>
          <ProgressBar
            striped
            className='progressbar_blue left'
            now={stats ? stats.smallPercentage * 100 : 50}
            key={1}
            label={stats ? `${Math.round(stats.smallPercentage * 100)}%` : ''}
          />
          <ProgressBar
            className='progressbar_green'
            now={stats ? (1 - stats.smallPercentage - stats.bigPercentage) * 100 : 0}
            key={2}
          />
          <ProgressBar
            striped
            className='progressbar_red right'
            now={stats ? stats.bigPercentage * 100 : 50}
            key={3}
            label={stats ? `${Math.round(stats.bigPercentage * 100)}%` : ''}
          />
        </ProgressBar>
        <div className='label'>大</div>
      </div>
      <div className='stat_row statistic_chips'>
        { form.length > 0 && ( <>
          { stats && [...stats.sumHistory].filter((_, i) => i > 0 && i < 24).reverse().map((s, i) => (
            <div className='cell' key={i}>{s}</div>
          ))}
          <div className="cell"></div>
          <div className="chip">{ stats ? stats.sumHistory[0] : null}</div>
        </>)}
      </div>
      <div className='stat_row statistic_boxes'>
        {dices.map((dice, index) => (
          <div className='col-box' key={index}>
            <div className='col-img'>
              <img src={dice} alt='dice' />
            </div>
            <div className='col-stat'>
              { stats ? `${Math.round(stats.occurrences[index] * 100)}%` : ''}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    game: bindActionCreators(GameActions, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(SicBoStatistic);
