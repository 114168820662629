import React from 'react';
import i18n, { t } from 'i18next';

import './ChangeLanguage.scss';

interface Props {
  callback: () => void;
}

export const ChangeLanguageDialog = (props: Props) => {

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    props.callback();
  };

  return (
    <div className='change_language dialog'>

      <div className='title'>{t('label.selectLanguage')}</div>
      <div className='selector'>
        <div className='list'>
          <button className='cn' onClick={() => changeLanguage('cn')}>中文 Chinese</button>
          <button className='en' onClick={() => changeLanguage('en')}>English 英文</button>
        </div>
      </div>
    </div>
  );
};
