import React from 'react';

import './GameHeader.scss';
import { GameHeaderCard } from './GameHeaderCard';

interface Props {
  startTime: string;
  balance: number;
  bet: number;
}

export const GameHeader = (props: Props) => {
  return (
    <div className='game_details'>
      <GameHeaderCard icon='time' value={props.startTime} />
      <GameHeaderCard icon='balance' value={props.balance || 0} />
      <GameHeaderCard icon='bets' value={props.bet} />
    </div>
  );
};
