import React from 'react';
import { useTranslation } from 'react-i18next';

export const Report = () => {
  const { t } = useTranslation();
  return (
    <div className='agent_body'>
      <div className='header_box'>
        <span className='sub_header_text'>Hp854876960</span>
      </div>
      <div className='row_details'>
        <div className='row_details_inner px_10'>
          <span className='row_details_text'>{t('report.startDate')}</span>
          <span className='row_details_text'>17/01/2022 00:00</span>
        </div>
        <div className='row_details_inner px_10'>
          <span className='row_details_text'>{t('report.endDate')}</span>
          <span className='row_details_text'>23/01/2022 23:59</span>
        </div>
        <div className='row_details_inner px_10'>
          <span className='row_details_text'>{t('report.numberOfDays')}</span>
          <span className='row_details_text'>7</span>
        </div>
      </div>
      <div className='row_details'>
        <div className='row_details_inner px_10'>
          <span className='row_details_text black'>{t('report.numberOfBets')}</span>
          <span className='row_details_text'>123456</span>
        </div>
        <div className='row_details_inner px_10'>
          <span className='row_details_text black'>{t('report.totalBets')}</span>
          <span className='row_details_text'>123456</span>
        </div>
        <div className='row_details_inner px_10'>
          <span className='row_details_text black'>{t('report.totalPayout')}</span>
          <span className='row_details_text'>123456</span>
        </div>
        <div className='row_details_inner px_10 border_black'>
          <span className='row_details_text black'>{t('report.profit')}</span>
          <span className='row_details_text'>123456</span>
        </div>
      </div>
      <div className='row_details'>
        <div className='row_details_inner'>
          <span className='row_details_text center w_100'>{t('report.generatedOn', {date: '24/01/2022 21:07'} )}</span>
        </div>
      </div>
    </div>
  );
};
