import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';

import './TrialResult.scss';
import { APP_BASE_PATH } from '../../../Constants';
import { TRIAL_DEFAULT_BALANCE } from '../../../environment.config';

interface Props {
  trialResult: number;
  startPlay: () => void;
}

const TrialResultDialog = (props: Props) => {
  const navigate = useNavigate();
  const [resultMessage, setResultMessage] = useState('');

  useEffect(() => {
    const result = props.trialResult - TRIAL_DEFAULT_BALANCE;
    if (result === 0) {
      setResultMessage('');
    } else if (result > 0) {
      setResultMessage(t('trialResult.youWon') + ' ' + result);
    } else {
      setResultMessage(t('trialResult.youLost') + ' ' + (result * -1));
    }
  }, [props.trialResult]);

  return (
    <div className='dialog trial_result'>
      { props.trialResult < 0 && (
        <div className='dialog_title'>{t('trialResult.title')}</div>
      )}
      <div className='dialog_content'>
        { props.trialResult < 0 && (
          <>
            <ol>
              <li className='result_message left'>{t('trialResult.rules1')}</li>
              <li className='result_message left'>{t('trialResult.rules2')}</li>
              <li className='result_message left'>{t('trialResult.rules3')}</li>
            </ol>
            <button className='to_play' onClick={props.startPlay}>{t('trialResult.letsPlay')}</button>
          </>
        )}
        {props.trialResult >= 0 && (
          <>
            <div className='result_message'>{t('trialResult.finalBalance')} <span className='balance'>{props.trialResult}</span></div>
            { resultMessage && (<div className={'result_message large ' + (props.trialResult > TRIAL_DEFAULT_BALANCE ? 'win' : 'lose')}>
              <span>{resultMessage}</span>
            </div>)}
            <div className='actions'>
              <button className='to_play' onClick={props.startPlay}>{t('trialResult.playAgain')}</button>
              <button className='to_home_page' onClick={() => navigate('/')}>{t('trialResult.homePage')}</button>
            </div>
            <div className='login_message'>{t('trialResult.loginMessage')}</div>
            <div className='login_action'>
              <button className='to_login' onClick={() => navigate(APP_BASE_PATH)}>{t('label.login')}</button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};


export const TrialResult = (props: Props) => {
  return (
    <>
      {typeof props.trialResult === 'number' && (
        <TrialResultDialog trialResult={props.trialResult} startPlay={props.startPlay} />
      )}
    </>
  );
};
