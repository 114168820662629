import React from 'react';
import reportWebVitals from './reportWebVitals';
import { composeWithDevTools } from 'redux-devtools-extension';
import { applyMiddleware, createStore } from 'redux';
// import logger from 'redux-logger';
import promise from 'redux-promise';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import initialState from './Store/initialState';
import rootReducer from './Store';
import AppRoulette from './Views/App/AppRoulette';
import AppBaccarat from './Views/App/AppBaccarat';
import PageNotFound from './Components/PageNotFound';
import Login from './Views/Login';
import { LoggedIn, RequireAuthAgent, RequireAuthManager, RequireAuthPlayer, } from './Routes';
import ChangePassword from './Components/ChangePassword';
import './variables.scss';

//Agent
import HomeAgent from './Views/Agent/HomeAgent';
import AgentDashboard from './Views/Agent/Home';
import AgentReport from './Views/Agent/Report';
import SuccessAgentreport from './Views/Agent/Report/SuccessReport';
import Setting from './Views/Agent/Setting';
import AgentChangePassword from './Views/Agent/ChangePassword';
import { AgentMessageList, AgentMessageNew, AgentMessageDetails } from './Views/Agent/Messages';

//Agent User
import UserDetail from './Views/Agent/User/UserDetailHome';
import AgentUserTransactions from './Views/Agent/User/Transaction';
import { AgentTransactionDetails, AgentTransactionList } from './Views/Agent/Transaction';
import Label from './Views/Agent/User/Label';
import AgentUserBetHistory from './Views/Agent/User/BetHistory';
import PlayerDetails from './Views/Agent/User/UserDetail';
import DepositCredit from './Views/Agent/User/DepositCredit';
import WithdrawCredit from './Views/Agent/User/WithdrawCredit';
import Report from './Views/Agent/User/Report';
import SuccessUserReport from './Views/Agent/User/Report/SuccessUserReport';

//Agent Add User
import User from './Views/Agent/User/Users';
import AddUser from './Views/Agent/User/AddUser';

//Manager
import HomeManager from './Views/Manager/HomeManager';
import ManagerDashboard from './Views/Manager/Home';
import ManagerReport from './Views/Manager/Report';
import SuccessManagerReport from './Views/Manager/Report/SuccessReport';
import ManagerSetting from './Views/Manager/Setting';
import ManagerChangepassword from './Views/Manager/ChangePassword';
import { ManagerMessageList, ManagerMessageNew, ManagerMessageDetails } from './Views/Manager/Messages';
import { ManagerTransactionDetails, ManagerTransactionList } from './Views/Manager/Transaction';

//Manager User
import UserListOutlet from './Components/Admin/UserListOutlet';
import AgentDetail from './Views/Manager/User/UserDetailHome';
import AgentTransactions from './Views/Manager/User/Transaction';
import AgentLabel from './Views/Manager/User/Label';
import ManagerUserDetails from './Views/Manager/User/UserDetail';
import AgentDepositCredit from './Views/Manager/User/DepositCredit';
import AgentWithdrawCredit from './Views/Manager/User/WithdrawCredit';
import ManagerReportSearch from './Views/Manager/User/Report';
import SuccessAgentreports from './Views/Manager/User/Report/SuccessReport';

//Manager Add user
import Agent from './Views/Manager/User/Users';
import AddAgent from './Views/Manager/User/AddUser';
import PlayerBetHistory from './Views/App/PlayerBetHistory';
import TransactionRecord from './Views/App/TransactionRecord';
import SoundVibrate from './Views/App/SoundVibrate';
import Start from './Views/App/Start';
import HomeApp from './Views/App/HomeApp';
import AppSicBo from './Views/App/AppSicbo';
import { APP_BASE_PATH, TRIAL_BASE_PATH } from './Constants';
import { LandingPage } from './Views/Website/LandingPage';
import { HomeTrial } from './Views/Trial/HomeTrial';
import { TrialStart } from './Views/Trial/TrialStart';
import { TrialBaccarat } from './Views/Trial/TrialBaccarat';
import { TrialSicBo } from './Views/Trial/TrialSicbo';
import { TrialRoulette } from './Views/Trial/TrialRoulette';

import EN_TRANSLATIONS from './i18n/en.json';
import CN_TRANSLATIONS from './i18n/cn.json';
import UserDetailsOutlet from './Components/Admin/UserDetailsOutlet';
import { createRoot } from 'react-dom/client';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: EN_TRANSLATIONS
      },
      cn: {
        translation: CN_TRANSLATIONS
      }
    },
    detection: {
      convertDetectedLanguage: (lng) => ['en', 'cn'].includes(lng.slice(0, 2)) ? lng.slice(0, 2) : 'cn',
    },
    fallbackLng: 'cn',

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

const composeEnhancers = composeWithDevTools({});
const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(thunk, promise))
  // composeEnhancers(applyMiddleware(thunk, promise, logger))
);

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path='Login' element={
            <LoggedIn>
              <Login />
            </LoggedIn>
          }/>
          <Route path='' element={<LandingPage />}/>
          <Route path={TRIAL_BASE_PATH} element={<HomeTrial />}>
            <Route index path='' element={<TrialStart />}/>
            <Route path='Baccarat' element={<TrialBaccarat />} />
            <Route path='Roulette' element={<TrialRoulette />} />
            <Route path='Sicbo' element={<TrialSicBo />} />
          </Route>

          <Route path={APP_BASE_PATH} element={
            <RequireAuthPlayer>
              <HomeApp />
            </RequireAuthPlayer>
          }>
            <Route index path='' element={<Start />}/>
            <Route path='SoundVibrate' element={<SoundVibrate />}/>
            <Route path='ChangePassword' element={<ChangePassword />}/>
            <Route path='TransactionRecord' element={<TransactionRecord />}/>
            <Route path='BetHistory' element={<PlayerBetHistory />}/>

            <Route path='Roulette' element={<AppRoulette />} />
            <Route path='Baccarat' element={<AppBaccarat />} />
            <Route path='SicBo' element={<AppSicBo />} />
          </Route>


          {/* Agent */}
          <Route path='Agent' element={
            <RequireAuthAgent>
              <HomeAgent />
            </RequireAuthAgent>
          }>
            <Route index element={<AgentDashboard />} />

            <Route path='ChangePassword' element={<ChangePassword />}/>
            <Route path='Report' element={<AgentReport />} />
            <Route path='SuccessReport' element={<SuccessAgentreport />}/>

            <Route path='Setting' element={<Setting />} />
            <Route path='Setting/AgentChangePassword' element={<AgentChangePassword />}/>

            <Route path='Messages'>
              <Route path='' element={<AgentMessageList />} />
              <Route path='New' element={<AgentMessageNew />} />
              <Route path=':id' element={<AgentMessageDetails />} />
            </Route>

            <Route path='Users' element={<UserListOutlet />}>
              <Route index element={<User />} />
              <Route path='Add' element={<AddUser />} />

              <Route path=':userId' element={<UserDetailsOutlet />}>
                <Route index element={<UserDetail />} />
                <Route path='DepositCredit' element={<DepositCredit />} />
                <Route path='WithdrawCredit' element={<WithdrawCredit />}/>

                <Route path='Transactions'>
                  <Route index element={<AgentUserTransactions />} />
                  <Route path=':id' element={<AgentTransactionDetails />} />
                </Route>

                <Route path='Messages'>
                  <Route path='' element={<AgentMessageList />} />
                  <Route path='New' element={<AgentMessageNew />} />
                  <Route path=':id' element={<AgentMessageDetails />} />
                </Route>

                <Route path='Report' element={<Report />} />
                <Route path='SuccessUserReport' element={<SuccessUserReport />}/>

                <Route path='Label' element={<Label />} />
                <Route path='BetHistory' element={<AgentUserBetHistory />} />
                <Route path='Details' element={<PlayerDetails />} />
              </Route>
            </Route>

            <Route path='Transactions'>
              <Route index element={<AgentTransactionList />} />
              <Route path=':id' element={<AgentTransactionDetails />} />
            </Route>
          </Route>

          {/* manager */}
          <Route path='Manager' element={
            <RequireAuthManager>
              <HomeManager />
            </RequireAuthManager>
          }>
            <Route index element={<ManagerDashboard />} />

            <Route path='Report' element={<ManagerReport />} />
            <Route path='SuccessReport' element={<SuccessManagerReport />}/>

            <Route path='Setting' element={<ManagerSetting />} />
            <Route path='Setting/AdminChangepassword' element={<ManagerChangepassword />}/>

            <Route path='Messages'>
              <Route path='' element={<ManagerMessageList />} />
              <Route path='New' element={<ManagerMessageNew />} />
              <Route path=':id' element={<ManagerMessageDetails />}/>
            </Route>

            <Route path='Users' element={<UserListOutlet />}>
              <Route index element={<Agent />} />
              <Route path='Add' element={<AddAgent />} />

              <Route path=':userId' element={<UserDetailsOutlet />}>
                <Route index element={<AgentDetail />} />
                <Route path='DepositCredit' element={<AgentDepositCredit />}/>
                <Route path='WithdrawCredit' element={<AgentWithdrawCredit />}/>
                <Route path='Transactions'>
                  <Route path='' element={<AgentTransactions />}/>
                  <Route path=':id' element={<ManagerTransactionDetails />} />
                </Route>
                <Route path='Messages'>
                  <Route path='' element={<ManagerMessageList />} />
                  <Route path='New' element={<ManagerMessageNew />}/>
                  <Route path=':id' element={<ManagerMessageDetails />}/>
                </Route>
                <Route path='Report' element={<ManagerReportSearch />} />
                <Route path='SuccessUserReport' element={<SuccessAgentreports />}/>
                <Route path='Label' element={<AgentLabel />} />
                <Route path='Details' element={<ManagerUserDetails />} />
              </Route>
            </Route>

            <Route path='Transactions'>
              <Route index element={<ManagerTransactionList />} />
              <Route path=':id' element={<ManagerTransactionDetails />} />
            </Route>
          </Route>

          <Route path='*' element={<PageNotFound />} />
        </Routes>
      </Router>
    </Provider>
  );
}

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
