import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';

import * as UserAction from '../../../Store/Actions/User';
import Header from '../../../Components/Header';
import { StartBalance, StartMessages, StartUserInfo } from '../../../Components/StartWidgets';
import './Home.scss';
import { StartPages } from '../../../Components/StartWidgets/StartPages';

const AgentDashboard = (props) => {
  const { t } = useTranslation();

  return (
    <div className='agent_layout agent_home'>
      <Header logo={true} name={t('Home')} agentMenu={true} />

      <div className='agent_body'>
        <StartUserInfo />
        <StartBalance actions={props.actions} />
        <StartMessages actions={props.actions} />
        <StartPages />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    userAction: bindActionCreators(UserAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(AgentDashboard);
