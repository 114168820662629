import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './LandingPage.scss';
import * as UserActions from '../../../Store/Actions/User';
import * as GameActions from '../../../Store/Actions/game.actions';
import { GameEngine } from '../../../Components/GameEngine';

const LandingPage = (props) => {
  return (
    <>
      <GameEngine
        title={'Play60s'}
        containerClass={'landing_page'}
        actions={props.actions}
        gameType={null}
        trial={true}
        noBet={true}
        login={true}
        website={true}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    user: bindActionCreators(UserActions, dispatch),
    game: bindActionCreators(GameActions, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(LandingPage);
