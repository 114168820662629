import { useEffect, useState } from 'react';

export function useAutoScroll(listElementClass: string) {
  const [scroll, setScroll] = useState(true);
  const [time] = useState(25);

  useEffect(() => {
    let interval = null;
    if (scroll) {
      interval = setInterval(() => {
        const el = document.getElementsByClassName(listElementClass)[0];
        if (el) {
          el.scrollBy(1, 0.5);
        } else {
          console.warn('Cannot find element to scroll: "' + listElementClass + '"');
        }
      }, time);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [scroll, time]);

  useEffect(() => {
    function stopScrolling() {
      setScroll(false);
    }
    function startScrolling() {
      setScroll(true);
    }
    const el = document.getElementsByClassName(listElementClass)[0];
    if (el) {
      el.addEventListener('pointerdown', stopScrolling);
      el.addEventListener('mousewheel', startScrolling);
    } else {
      console.warn('Cannot find element to scroll: "' + listElementClass + '"');
    }

    return () => {
      const el = document.getElementsByClassName(listElementClass)[0];
      if (el) {
        el.addEventListener('pointerdown', stopScrolling);
        el.addEventListener('mousewheel', startScrolling);
      }
    };
  }, []);

  return [scroll, time];
}
