import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { useGameReducer, useUser } from '../../../Store';
import { DATE_FORMAT, TIME_FORMAT } from '../../../environment.config';

export const UserTransactions = () => {
  const navigate = useNavigate();
  const gameReducer = useGameReducer();
  const [transactions, setTransactions] = useState([]);
  const user = useUser(useParams().userId);
  const { t } = useTranslation();

  useEffect(() => {
    let transactions = [];
    if (gameReducer.creditTransactions) {
      transactions = gameReducer.creditTransactions.map((transaction) => ({
        ...transaction,
        creditAmount: transaction.debitAmount,
        debitAmount: transaction.creditAmount,
        // details: transaction.details === 'Credit' ? 'Withdraw' : 'Credit',
        created: moment(transaction.created).format(DATE_FORMAT),
        createdTime: moment(transaction.created).format(TIME_FORMAT),
      }));
    }
    if (transactions) {
      const groupDate = _.chain(transactions)
        .groupBy('created')
        .map((value, key) => ({ created: key, transactions: value }))
        .value();

      setTransactions(groupDate);
    }
  }, [gameReducer.creditTransactions]);

  const open = (id: string) => {
    navigate(id);
  };

  return (
    <div className='agent_body full_width'>
      <div className='header_box'>
        <span className='sub_header_text'>{ user.user.name }</span>
      </div>

      <div className='full_table button_rows two_col' id='transaction_table'>
        <div className='table_header'>
          <span className='table_header_text'>{t('transactions.time')}</span>
          <span className='table_header_text'>{t('transactions.amount')}</span>
        </div>
        <div className='table_body_main'>
          { transactions.map((singlegroup, id) => (
            <div className='table_body_inner' key={ id }>
              <div className='table_body_inner' key={ id }>
                <div className='table_body_section'>
                  <span className='section_title'>{ singlegroup.created }</span>
                </div>
                <div className='table_body'>
                  { singlegroup.transactions.map((singleuser, id) => (
                    <div className='table_body_list' key={ id } onClick={() => open(singleuser.id)}>
                      <span
                        className='body_list'
                        style={ {
                          display: 'block',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        } }
                      >
                        { singleuser.createdTime }
                      </span>
                      <span className='body_list'>
                        {singleuser.details !== 'Credit' ? ('+' + singleuser.creditAmount) : ('-' + singleuser.debitAmount)}
                      </span>
                    </div>
                  )) }
                </div>
              </div>
            </div>
          )) }
        </div>
      </div>
    </div>
  );
};
