import React from 'redux';
import { useTranslation } from 'react-i18next';

import './ChangeLanguageButton.scss';

interface Props {
  narrow?: boolean
}

export const ChangeLanguageButton = (props: Props) => {
  const { i18n} = useTranslation();

  const toggleLanguage = () => {
    i18n.changeLanguage(i18n.language == 'en' ? 'cn' : 'en');
  };

  return (<button className={'language' + (props.narrow ? ' narrow': '')} onClick={() => toggleLanguage()}>
    <span className={i18n.language == 'cn' ? 'selected' : ''}>中</span>
    <span className={i18n.language == 'en' ? 'selected' : ''}>EN</span>
  </button>);
};
