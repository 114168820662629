import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as UserAction from '../../../Store/Actions/User';

import './Transaction.scss';
import { TransactionDetails } from '../../../Components/Admin/TransactionDetails';
import { FooterMenu } from '../../../Components/FooterMenu';

const AgentTransactionDetails = () => {
  return (
    <div className='agent_layout'>
      <TransactionDetails />
      <FooterMenu />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    userAction: bindActionCreators(UserAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(AgentTransactionDetails);
