import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';

import './PlayerBetHistory.scss';
import * as UserAction from '../../../Store/Actions/User';
import Header from '../../../Components/Header';
import { BetHistory } from '../../../Components/BetHistory';

const PlayerBetHistory = (props) => {
  const { t } = useTranslation();

  return (
    <div className='page_container bet_history'>
      <Header name={t('menu.betHistory')} />
      <div className='page_content full'>
        <BetHistory actions={props.actions} />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    userAction: bindActionCreators(UserAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(PlayerBetHistory);
