import { cloneDeep } from 'lodash';

export interface Chip {
  id: string
  value: number,
  index: number;
  borderColor: string;
}

const defaultChips: Chip[] = [
  {
    id: '5',
    value: 5,
    index: 1,
    borderColor: 'chip2',
  },
  {
    id: '10',
    value: 10,
    index: 2,
    borderColor: 'chip3',
  },
  {
    id: '50',
    value: 50,
    index: 3,
    borderColor: 'chip4',
  },
  {
    id: '100',
    value: 100,
    index: 4,
    borderColor: 'chip1',
  },
  {
    id: '500',
    value: 500,
    index: 5,
    borderColor: 'chip2',
  },
  {
    id: '1000',
    value: 1000,
    index: 6,
    borderColor: 'chip3',
  },
  {
    id: '5000',
    value: 5000,
    index: 7,
    borderColor: 'chip4',
  },
  {
    id: '10000',
    value: 10000,
    index: 8,
    borderColor: 'chip1',
  },
  {
    id: '50000',
    value: 50000,
    index: 9,
    borderColor: 'chip2',
  },
  {
    id: '100000',
    value: 100000,
    index: 10,
    borderColor: 'chip3',
  },
  {
    id: '500000',
    value: 500000,
    index: 11,
    borderColor: 'chip4',
  },
];

export const getDefaultChips = () => cloneDeep(defaultChips);

export const getTopAvailableChip = (_chips: Chip[], availableBalance: number) => {
  return _chips.reduce((acc, cur) => {
    if (parseInt(acc) < cur.value && cur.value <= availableBalance) {
      return cur.id;
    }
    return acc;
  }, '0');
};
