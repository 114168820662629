import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';

import { useUser } from '../../../Store';

interface Props {
  actions: any
}

export const UserDetailEditor = (props: Props) => {
  const navigate = useNavigate();
  const user = useUser(useParams().userId);
  const [userDetails, setUserDetails] = useState({details: ''});
  const { t } = useTranslation();

  const handleChange = (event) => {
    return setUserDetails({...userDetails, details: event.target.value});
  };

  const submit = () => {
    props.actions.userAction.setUserDetails(user.user.name, userDetails).then(() => {
      navigate('../');
    });
  };

  useEffect(() => {
    setUserDetails({details: user.user.details});
  }, [user]);

  return (
    <div className='agent_body'>
      <div className='header_box'>
        <span className='sub_header_text'>{user.user.name}</span>
      </div>
      <ValidatorForm
        className='input_form_box'
        onSubmit={() => submit()}
        autoComplete='off'
      >
        <div className='input_form_box_inner'>
          <div className='input_form'>
            <span className='input_box_text'>{t('user.details')}</span>
            <TextValidator
              name='details'
              type='text'
              validators={['required']}
              errorMessages={['this field is required']}
              variant='outlined'
              size='small'
              multiline
              minRows={3}
              className='input_box'
              value={userDetails.details}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className='form_btn'>
          <Button type='submit' variant='contained' className='submit_btn'>
            {t('label.submit')}
          </Button>
        </div>
      </ValidatorForm>
    </div>
  );
};
