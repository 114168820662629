import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Header from '../../../../Components/Header';
import './BetHistory.scss';
import * as UserAction from '../../../../Store/Actions/User';
import { BetHistory } from '../../../../Components/BetHistory';
import { FooterMenu } from '../../../../Components/FooterMenu';

const AgentUserBetHistory = (props) => {
  const { userId } = useParams();
  const { t } = useTranslation();

  return (
    <div className='page_container bet_history'>
      <Header name={t('menu.betHistory')} />
      <div className="page_content full">
        <BetHistory userId={userId} actions={props.actions} />
      </div>
      <FooterMenu />
    </div>
  );
};


const mapDispatchToProps = (dispatch) => ({
  actions: {
    userAction: bindActionCreators(UserAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(AgentUserBetHistory);
