import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Header from '../../../../Components/Header';
import { FooterMenu } from '../../../../Components/FooterMenu';
import { UserDetailEditor } from '../../../../Components/Admin/Users/UserDetailEditor';
import * as UserAction from '../../../../Store/Actions/User';

const ManagerUserDetails = (props) => {
  const { t } = useTranslation();

  return (
    <div className='agent_layout'>
      <Header name={t('user.details')} />
      <UserDetailEditor actions={props.actions} />
      <FooterMenu isManager={true}/>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    userAction: bindActionCreators(UserAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(ManagerUserDetails);
