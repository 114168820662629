import React, { useEffect, useRef, useState } from 'react';

import './WebsitePageHeader.scss';
import { TrialViewModes } from '../TrialViewModes';
import { StepId } from '../../../Constants/gameSteps';
import { t } from 'i18next';

interface Props {
  trialViewMode: TrialViewModes,
  startTime: string;
  resultTime: string;
  minutes: number;
  stepId: StepId;
}

export const WebsitePageHeader = (props: Props) => {
  const [cardColor, setCardColor] = useState('');
  const [sectionTitle, setSectionTitle] = useState('');
  const [sectionTitleLabel, setSectionTitleLabel] = useState('');

  useEffect(() => {
    switch (props.stepId) {
      case StepId.TOP_BETS: setCardColor('red'); break;
      case StepId.RESULT:
      case StepId.HIGHLIGHT_WINNER_BETS:
      case StepId.HIGHLIGHT_BETS:
      case StepId.TOP_WINNERS:
        setCardColor('green'); break;
      default: setCardColor('');
    }
    if (props.stepId === StepId.GAME || props.stepId === StepId.LATEST_RESULT) {
      switch (props.trialViewMode) {
        case TrialViewModes.LATEST: setSectionTitle(props.resultTime ? `${t('gameState.latestResult')} [${props.resultTime}]` : ''); break;
        case TrialViewModes.STAT: setSectionTitle(t('gameState.statistics')); break;
        case TrialViewModes.RESULTS: setSectionTitle(t('gameState.pastResult')); break;
      }
    } else {
      switch (props.stepId) {
        case StepId.TOP_BETS: setSectionTitle(`${t('gameState.betClosed')} [${props.startTime}]`); break;
        default: setSectionTitle(`${t('gameState.result')} [${props.startTime}]`); break;
      }
    }
  }, [props.stepId, props.trialViewMode]);

  const updateNotificationRef = useRef();
  useEffect(() => {
    // @ts-ignore
    updateNotificationRef?.current?.animate({
      marginTop: ['40px', 0, 0, 0, '-40px'],
      opacity: [0, 1, 1, 1, 0]
    }, 1010);
  }, [props.minutes]);

  const updateSectionRef = useRef();
  useEffect(() => {
    // @ts-ignore
    updateSectionRef?.current?.animate({
      marginTop: [0, '40px', '-40px', 0],
      opacity: [1, 0, 0, 1]
    }, 400);
    setTimeout(() => {
      setSectionTitleLabel(sectionTitle);
    }, 200);
  }, [sectionTitle]);

  const updateStartTimeRef = useRef();
  useEffect(() => {
    // @ts-ignore
    updateStartTimeRef?.current?.animate({
      transform: ['scale(1)', 'scale(1.3)', 'scale(1)', ],
    }, 400);
  }, [props.startTime]);

  return (
    <>
      <div className='subheader'>
        <div className='messages'>
          <span className='red'>
            <span>{t('website.headerMessage.line1a')} </span>
            <span>{t('website.headerMessage.line1b')} </span>
            <span>{t('website.headerMessage.line1c')}</span>
          </span>
          <span>{t('website.headerMessage.line2')}</span>
          <span>{t('website.headerMessage.line3')}</span>
          <span className='red first_only'>
            <span>{t('website.headerMessage.line1a')} </span>
            <span>{t('website.headerMessage.line1b')} </span>
            <span>{t('website.headerMessage.line1c')}</span>
          </span>
        </div>
      </div>
      <div className={'game_cards ' + cardColor}>
        <div className='game_card'>
          <i className="fa-solid fa-clock"/>
          <span
            ref={updateStartTimeRef}
            className={'game_counting_text_inner'}
            style={{transform: 'scale(1)'}}
          >{props.startTime}</span></div>
        <div className='game_card'>
          <i className="fa-solid fa-stopwatch"/>
          { props.stepId === StepId.LATEST_RESULT && (
            <span
              ref={updateNotificationRef}
              className={'game_counting_text_inner'}
              style={{marginTop: '40px', opacity: 0}}
            >{props.minutes}</span>
          )}
          { props.stepId !== StepId.LATEST_RESULT && (<>...</>)}
        </div>
      </div>
      <div className='view_mode_title'>
        <div
          className='view_mode_title_text'
          ref={updateSectionRef}
          style={{transform: 'scale(1)'}}
        >{sectionTitleLabel}</div></div>
    </>
  );
};
