import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

import { useUser } from '../../../Store';
import { roundBalance } from '../../../Utils';
import { DATE_FORMAT } from '../../../environment.config';

interface Props {
  isAgent?: boolean
}

export const UserDetail = (props: Props) => {
  const navigate = useNavigate();
  const user = useUser(useParams().userId);
  const { t } = useTranslation();

  const redirect = (url) => {
    navigate(url);
  };

  return (
    <>
      { user && (
        <div className='agent_body'>
          <div className='row_details'>
            <div className='header_box'>
              <span className='row_details_text'>{user.user.name}</span>
            </div>

            <div className='row_details_inner'>
              <span className='row_details_text'>{t('user.balance')}</span>
              <span className='row_details_text'>{roundBalance(parseFloat(user.balance))}</span>
            </div>

            <div className='row_details_inner'>
              <span className='row_details_text'>{t('user.created')}</span>
              <span className='row_details_text'>{moment(user.user.created).format(DATE_FORMAT)}</span>
            </div>
          </div>

          <div className='game_details_box'>
            <div className='game_details_box_inner'>
              <div className='details_box_btn lm_btn' onClick={() => redirect('./DepositCredit')}>
                <span className='btn_text wheat '>{t('userAction.deposit')}</span>
              </div>
              <div className='details_box_btn lm_btn' onClick={() => redirect('WithdrawCredit')}>
                <span className='btn_text wheat '>{t('userAction.withdraw')}</span>
              </div>
            </div>

            <div className='game_details_box_inner'>
              <div className='details_box_btn lm_btn' onClick={() => redirect('Transactions')}>
                <span className='btn_text wheat '>{t('userAction.transactions')}</span>
              </div>
              <div className='details_box_btn lm_btn' onClick={() => redirect('../../Messages/' + user.user.id)}>
                <span className='btn_text wheat '>{t('userAction.messages')}</span>
              </div>
            </div>

            <div className='game_details_box_inner'>
              <div className='details_box_btn lm_btn' onClick={() => redirect('Report')}>
                <span className='btn_text wheat '>{t('userAction.report')}</span>
              </div>
              { !props.isAgent && (
                <div className='details_box_btn lm_btn' onClick={() => redirect('BetHistory')}>
                  <span className='btn_text wheat '>{t('userAction.betHistory')}</span>
                </div>
              )}
            </div>
          </div>

          <div className='row_details'>
            <div className='row_details_inner'>
              <span className='row_details_text'>{t('user.label')} <EditIcon onClick={() => redirect('Label')} /></span>
              <span className='row_details_text'>
                {user.user.labels.length ? user.user.labels[1] : ''}
              </span>
            </div>

            <div className='row_details_inner'>
              <span className='row_details_text'>{t('user.details')} <EditIcon onClick={() => redirect('Details')} /></span>
              <span className='row_details_text'>{user.user.details}</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
