import React from 'react';
import { useTranslation } from 'react-i18next';

import Header from '../../../../Components/Header';
import { FooterMenu } from '../../../../Components/FooterMenu';
import { PlayerReportSearch } from '../../../../Components/Admin/Users/ReportSearch';

const Report = () => {
  const { t } = useTranslation();
  return (
    <div className='agent_layout'>
      <Header name={t('userAction.report')} agentMenu={true} />
      <PlayerReportSearch />
      <FooterMenu />
    </div>
  );
};

export default Report;
