import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './FooterMenu.scss';
import '@fontsource/inter';

interface Props {
  isManager?: boolean
}

export const FooterMenu = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const baseUrl = props.isManager ? '/Manager' : '/Agent';

  const redirect = (url) => {
    navigate(url);
  };
  return (
    <div className='footer_menu'>
      <div className='sub_menu home' onClick={() => redirect(baseUrl)}>
        <img src='/img/page_home.svg' alt={t('menu.home')}/>
      </div>
      <div className='sub_menu player' onClick={() => redirect(baseUrl + '/Users')}>
        <img src='/img/page_player.svg' alt={t('menu.players')}/>
      </div>

      <div className='sub_menu add_player' onClick={() => redirect(baseUrl + '/Users/Add')}>
        <img src='/img/page_add_player.svg' alt={t('menu.addPlayer')}/>
      </div>

      <div className='sub_menu message' onClick={() => redirect(baseUrl + '/Messages')}>
        <img src='/img/page_message.svg' alt={t('menu.messages')}/>
      </div>

      <div className='sub_menu transaction' onClick={() => redirect(baseUrl + '/Transactions')}>
        <img src='/img/page_transaction.svg' alt={t('menu.transactions')}/>
      </div>
    </div>
  );
};
