import { useEffect, useState } from 'react';
import React from 'redux';

import { useGameReducer } from '../../Store';
import './StartUserInfo.scss';

export const StartUserInfo = () => {
  const gameReducer = useGameReducer();
  const [name, setName] = useState('');

  useEffect(() => {
    if (gameReducer.userDetail) {
      setName(gameReducer.userDetail.name);
    }
  }, [gameReducer.userDetail]);

  return (
    <div className='user_info_widget'>
      <div className='user_name'>
        <div className='user_name_icon'></div>
        <div className='user_name_name'>{name}</div>
      </div>
      {/*<div className='user_id'>{name}</div>*/}
    </div>
  );
};
