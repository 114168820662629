import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import '@fontsource/inter';
import '@fortawesome/fontawesome-free/css/all.min.css';

import * as GameActions from '../../../Store/Actions/game.actions';
import * as UserActions from '../../../Store/Actions/User';
import PageNotSpotted from '../../../Components/PageNotSpotted';
import { GameType } from '../../../Utils';
import './AppSicBo.scss';
import { GameEngine } from '../../../Components/GameEngine';

const AppSicBo = (props) => {
  return (
    <>
      <GameEngine
        title='骰宝 Sic Bo'
        containerClass='homesicbo'
        actions={props.actions}
        gameType={GameType.SicBo}
      />
      <PageNotSpotted />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    game: bindActionCreators(GameActions, dispatch),
    user: bindActionCreators(UserActions, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(AppSicBo);
