import React from 'react';
import './NumberBetFrame.scss';
import ChipsOnField from '../../ChipsOnField';

const NumberBetFrame = (props) => {
  const isActive = props.value.isActive === true ? 'selected_bet' : '';

  return (
    <span
      key={props.value.value}
      onClick={() => props.handleClickRow(props.value)}
      className={`game_bet_box ${props.value.color} ${isActive}`}
    >
      <span className='game_bet_box_border'>
        {props.value.value}
      </span>

      <ChipsOnField data={props.value}></ChipsOnField>
    </span>
  );
};

export default NumberBetFrame;
