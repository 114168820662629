import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { FooterMenu } from '../../../Components/FooterMenu';
import * as UserAction from '../../../Store/Actions/User';

import './Transaction.scss';
import { TransactionDetails } from '../../../Components/Admin/TransactionDetails';

const ManagerTransactionDetails = () => {
  return (
    <div className='agent_layout'>
      <TransactionDetails />
      <FooterMenu isManager={true} />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    userAction: bindActionCreators(UserAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(ManagerTransactionDetails);
