import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import * as UserAction from '../../Store/Actions/User';
import { useUser } from '../../Store';

const UserDetailsOutlet = () => {
  const navigate = useNavigate();
  const user = useUser(useParams().userId);

  useEffect(() => {
    if (!user) {
      navigate('/Manager/Users');
    }
  }, [user]);

  return user ? <Outlet /> : <></>;
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    userAction: bindActionCreators(UserAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(UserDetailsOutlet);
