import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  callback: () => void;
}

export const ConnectionProblem = (props: Props) => {
  const {t} = useTranslation();
  const reloadFn = () => {
    if (typeof props.callback === 'function') {
      props.callback();
    }
  };

  return (
    <div className='center_dialog_box dialog_message connection_problem'>
      <div className='title'>{t('error.networkProblem')}</div>
      <div className='message'>{t('error.reloadMessage')}</div>
      <div className='action'>
        <button onClick={reloadFn}>{t('label.reload')}</button>
      </div>
    </div>
  );
};
