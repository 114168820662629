import React from 'react';
import { useTranslation } from 'react-i18next';

import { FooterMenu } from '../../../../Components/FooterMenu';
import Header from '../../../../Components/Header';
import { Report } from '../../../../Components/Admin/Users/Report';

const SuccessUserReport = () => {
  const { t } = useTranslation();

  return (
    <div className='agent_layout'>
      <Header name={t('userAction.report')} />
      <Report />
      <FooterMenu />
    </div>
  );
};

export default SuccessUserReport;
