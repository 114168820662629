import React from 'react';
import { useNavigate } from 'react-router-dom';

import './GamePicker.scss';
import { GameCard } from './GameCard';
import { GameType } from '../../Utils';

export const GamePicker = () => {
  const navigate = useNavigate();

  return (
    <div className='game_picker'>
      <GameCard
        gameType={GameType.Baccarat}
        onClick={() => navigate('Baccarat')}
      />

      <GameCard
        gameType={GameType.SicBo}
        onClick={() => navigate('SicBo')}
      />

      <GameCard
        gameType={GameType.Roulette}
        onClick={() => navigate('Roulette')}
      />
    </div>
  );
};

