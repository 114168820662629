import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import * as UserAction from '../../../../Store/Actions/User';
import Header from '../../../../Components/Header';
import {
  IN_PROGRESS,
  SUCCESS_MESSAGE,
  ERROR,
  LOGOUT,
} from '../../../../Constants';
import { clearAccessToken } from '../../../../Utils';
import './AddUser.scss';
import { FooterMenu } from '../../../../Components/FooterMenu';

const AddAgent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userDetail, setUserDetail] = useState({
    name: '',
    details: '',
    password: '',
    confirmPassword: '',
  });

  const handleChange = (event) => {
    const field = event.target.name;
    const commonData = { ...userDetail };
    commonData[field] = event.target.value;
    return setUserDetail(commonData);
  };

  const addUser = () => {
    dispatch({ type: IN_PROGRESS });
    if (
      !userDetail.name ||
      !userDetail.password ||
      userDetail.name === '' ||
      userDetail.password === ''
    ) {
      return dispatch({
        type: ERROR,
        data: { error_msg: t('error.allRequired') },
      });
    }

    if (userDetail.password !== userDetail.confirmPassword)
      return dispatch({
        type: ERROR,
        data: { error_msg: t('error.passNotMatch') },
      });

    UserAction.addUser(userDetail)
      .then((response) => {
        if (response && response.status === 200) {
          dispatch({ type: SUCCESS_MESSAGE, data: { message: null } });
          navigate('/Agent/Users');
          toast.success(t('message.userSaved'));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          if (error.response.status === 403) {
            clearAccessToken();
            dispatch({
              type: LOGOUT,
            });
          } else
            dispatch({
              type: ERROR,
              data: { error_msg: error.response.data.message },
            });
        } else {
          dispatch({
            type: ERROR,
            data: { error_msg: error.message.toString() },
          });
        }
      });
  };

  return (
    <div className='agent_layout'>
      <Header name={t('manager.addAgent')} />
      <div className='agent_body'>
        <ValidatorForm
          className='input_form_box'
          onSubmit={() => addUser()}
          autoComplete='off'
        >
          <div className='input_form_box_inner'>
            <div className='input_form'>
              <TextValidator
                validators={['required']}
                errorMessages={[t('error.fieldRequired')]}
                variant='outlined'
                placeholder={t('manager.addAgent.username')}
                size='small'
                type='text'
                className='input_box'
                name='name'
                value={userDetail.name}
                onChange={handleChange}
              />
            </div>
            <div className='input_form'>
              <TextValidator
                validators={['required']}
                errorMessages={[t('error.fieldRequired')]}
                variant='outlined'
                placeholder={t('manager.addAgent.password')}
                size='small'
                type='password'
                className='input_box'
                name='password'
                value={userDetail.password}
                onChange={handleChange}
              />
            </div>
            <div className='input_form'>
              <TextValidator
                validators={['required']}
                errorMessages={[t('error.fieldRequired')]}
                variant='outlined'
                size='small'
                placeholder={t('manager.addAgent.passwordConfirm')}
                type='password'
                className='input_box'
                value={userDetail.confirmPassword}
                name='confirmPassword'
                onChange={handleChange}
              />
            </div>
            <div className='input_form'>
              <TextValidator
                className='input_box'
                name='details'
                value={userDetail.details}
                placeholder={t('manager.addAgent.details')}
                type='text'
                validators={['required']}
                errorMessages={[t('error.fieldRequired')]}
                variant='outlined'
                size='small'
                multiline
                rows={3}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className='form_btn'>
            <Button type='submit' variant='contained' className='submit_btn'>
              {t('label.submit')}
            </Button>
          </div>
        </ValidatorForm>
      </div>
      <FooterMenu isManager={true}/>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    userAction: bindActionCreators(UserAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(AddAgent);
