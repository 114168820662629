import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './PayoutInfo.scss';
import { GameType } from '../../../Utils';

interface Props {
  gameType: GameType;
  close: () => void;
}

const PayoutInfoDialog = (props: Props) => {
  const { t } = useTranslation();
  const [ payout, setPayout ] = useState([]);
  const [ flag, setFlag ] = useState('');

  useEffect(() => {
    switch (props.gameType) {
      case GameType.Baccarat:
        setPayout([
          [t('baccarat.player'), '1+1'],
          [t('baccarat.banker'), '0.95+1'],
          [t('baccarat.tie'), '8+1'],
          [t('baccarat.player_pair'), '11+1'],
          [t('baccarat.banker_pair'), '11+1'],
        ]);
        setFlag(t('baccarat.payout_flag'));
        break;
      case GameType.Roulette:
        setPayout([
          [t('0, 1 - 18'), '17+1'],
          [t('1-9, 10-18'), '1+1'],
          [t('roulette.even') + ', ' + t('roulette.odd'), '1+1'],
          [t('roulette.red') + ', ' + t('roulette.black'), '1+1'],
        ]);
        setFlag(t('roulette.payout_flag'));
        break;
      case GameType.SicBo:
        setPayout([
          [t('sicbo.range_big') + ' ' + t('sicbo.range_small'), '1+1', t('sicbo.noPayoutOnTriple')],
          [t('sicbo.on_1_dice'), '1+1'],
          [t('sicbo.on_2_dice'), '2+1'],
          [t('sicbo.on_3_dice'), '3+1'],
          [t('4, 17'), '50+1'],
          [t('5, 16'), '18+1'],
          [t('6, 15'), '14+1'],
          [t('7, 14'), '12+1'],
          [t('8, 13'), '8+1'],
          [t('9, 10, 11, 12'), '6+1'],
          [t('sicbo.triple_spec'), '150+1'],
          [t('sicbo.triple_any'), '24+1'],
        ]);
        break;
    }
  }, []);

  return (
    <div className='dialog payout_info'>
      <div className='dialog_title'>{t('gameType.' + props.gameType)} {t('payout_table')}</div>
      <div className='dialog_content'>
        <table>
          <thead>
            <tr>
              <th>{t('history.betType')}</th>
              <th>{t('history.payout')}</th>
            </tr>
          </thead>
          <tbody>
            { payout.map((row, index) => (
              <tr key={index}>
                <td>
                  {row[0]}
                  {row[2] && (<span>{row[2]}</span>)}
                </td>
                <td>{row[1]}</td>
              </tr>
            ))}
          </tbody>
        </table>
        { flag && (
          <div className="flag">{flag}</div>
        )}
        <button onClick={() => props.close()}>{t('label.close')}</button>
      </div>
    </div>
  );
};


export const PayoutInfo = (props: Props) => {
  return (
    <>
      <PayoutInfoDialog gameType={props.gameType} close={props.close} />
    </>
  );
};
