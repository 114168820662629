import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';

import './MessageList.scss';
import { Message, useGameReducer, User } from '../../Store';
import { DATETIME_FORMAT } from '../../environment.config';

interface Props {
  actions: { userAction: any };
}

export const MessageDetails = (props: Props) => {
  const { id } = useParams();
  const gameReducer = useGameReducer();
  const [user, setUser] = useState<User>();
  const [newMessage, setNewMessage] = useState('');
  const [messages, setMessages] = useState<{ sent: boolean, message: Message }[]>([]);

  useEffect(() => {
    if (user) {
      fetchMessages();
    }
  }, [user]);

  useEffect(() => {
    if (gameReducer.userDetail.role !== 5 && gameReducer.users) {
      props.actions.userAction.getUsers();
    }
  }, []);

  useEffect(() => {
    if (id) {
      if (gameReducer.operator?.id === id) {
        setUser(gameReducer.operator);
      } else {
        setUser(gameReducer.users.find(u => u.user.id === id)?.user);
      }
    }
  }, [id, gameReducer.operator, gameReducer.users]);


  const fetchMessages = async()  => {
    props.actions.userAction.getMessages(gameReducer.accountId, id).then(m1 => {
      props.actions.userAction.getMessages(id, gameReducer.accountId).then(m2 => {
        setMessages([...m1, ...m2].map((m: Message) => ({
          sent: m.recipientId === id,
          message: m,
        })).sort((a, b) => a.message.createdAt.localeCompare(b.message.createdAt)).reverse());
      });
    });
  };

  const handleSubmit = (e) => {
    if (user && newMessage) {
      props.actions.userAction.sendMessage(user.id, '', newMessage).then(() => {
        setNewMessage('');
      });
    }
    e.preventDefault();
  };

  return (
    <div className='messages_body'>
      <div className='header_box'>
        <span className='sub_header_text'>{user?.name}</span>
      </div>

      <div className="send_box">
        <form
          onSubmit={handleSubmit}
          autoComplete='off'>
          <textarea value={newMessage} onChange={e => setNewMessage(e.target.value)}></textarea>
          <button>Send</button>
        </form>
      </div>

      <div className='message_list'>
        {messages?.map((message, i) => {
          const date = moment(message.message.createdAt);
          return (
            <div className={'message ' + (message.sent ? 'sent' : 'received')} key={'message.' + i}>
              <div className='message_meta'>
                <span className='sender'>{message.sent ? gameReducer.userDetail.name : user.name}</span>
                <span className='date'>{date.format(DATETIME_FORMAT)}</span>
              </div>
              <div className='message_body'>
                <span className='body_list'>
                  {message.message.body}</span>
              </div>
            </div>
          );
        })}
      </div>

    </div>
  );
};
