import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import gameReducer from './game.reducer';

const rootReducer = combineReducers({
  gameReducer,
  routing: routerReducer,
});

export * from './app.state';
export * from './selectorHooks';
export default rootReducer;
