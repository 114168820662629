import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Header from '../../../Components/Header';
import { FooterMenu } from '../../../Components/FooterMenu';
import { MessageDetails } from '../../../Components/Admin/MessageDetails';
import * as UserAction from '../../../Store/Actions/User';

const ManagerMessageDetails = (props) => {
  const { t } = useTranslation();

  return (
    <div className='agent_layout'>
      <Header name={t('menu.message')} />

      <div className='agent_body'>
        <MessageDetails actions={props.actions} />
      </div>

      <FooterMenu isManager={true}/>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    userAction: bindActionCreators(UserAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(ManagerMessageDetails);
