import React from 'react';

import Header from '../../../../Components/Header';
import { Report } from '../../../../Components/Admin/Users/Report';
import { FooterMenu } from '../../../../Components/FooterMenu';

const ManagerSuccessReports = () => {

  return (
    <div className='agent_layout'>
      <Header name={'Agent Report'} />
      <Report />
      <FooterMenu isManager={true}/>
    </div>
  );
};


export default ManagerSuccessReports;
