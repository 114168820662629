/* eslint-disable array-callback-return */
/* eslint-disable no-unreachable */
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import { reject } from 'lodash';

import {
  ERROR,
  GET_GAME_TIME,
  GET_MESSAGES,
  GET_PAST_RESULTS,
  GET_TOP_BETS,
  GET_TOP_WINNERS,
  GET_USER,
  GET_USER_BALANCE_MESSAGES,
  GET_USER_BETS,
  GET_USER_TRANSACTION,
  GET_USERS, INIT_TRIAL_BALANCE,
  IN_PROGRESS,
  IS_AUTHENTICATED,
  LOGOUT,
  PAGINATION_LIMIT,
  RESET_GAME,
  RESET_USER_TRANSACTION,
  SUCCESS_MESSAGE,
  ALL_RESULT,
  ALL_SHOW_WINNING_AREA,
  ALL_SHOW_RESULT_AREA,
  COMPLETE,
  SET_USER_DETAILS,
} from '../../Constants';
import ENVIRONMENT_VARIABLES, { TIME_FORMAT } from '../../environment.config';
import {
  clearAccessToken,
  getRequestConfig,
  getUserDetail,
  requestError,
  retryRequest,
  setAccessToken,
  setUserDetail,
} from '../../Utils';
import { GameSession } from '../app.state';

export const login = (loginDetails) => (dispatch) => {
  dispatch({ type: IN_PROGRESS });
  return new Promise((resolve, reject) => {
    axios
      .post(ENVIRONMENT_VARIABLES.Base_API_URL + '/user/login', loginDetails)
      .then((response) => {
        setAccessToken(response.data.accessToken);
        dispatch({
          type: IS_AUTHENTICATED,
          data: { accessToken: response.data.accessToken },
        });
        resolve(true);
      })
      .catch(requestError(dispatch, reject));
  });
};

export const logout = () => (dispatch) => {
  dispatch({ type: IN_PROGRESS });
  const token = localStorage.getItem('accessToken');
  const api = {
    method: 'POST',
    headers: { Authorization: token },
    url: ENVIRONMENT_VARIABLES.Base_API_URL + '/user/logout',
  };
  return new Promise((resolve, reject) => {
    axios(api)
      .then(() => {
        clearAccessToken();
        dispatch({
          type: LOGOUT,
        });
        resolve(true);
      })
      .catch(() => {
        requestError(null, reject);
        clearAccessToken();
        dispatch({
          type: LOGOUT,
        });
      });
  });
};

export const getUserDetails = () => (dispatch) => new Promise((resolve, reject) => {
  dispatch({ type: IN_PROGRESS });
  axios(getRequestConfig('/user'))
    .then((response) => {
      setUserDetail(response.data.user);
      dispatch({
        type: GET_USER,
        data: { userDetail: response.data.user },
      });
      resolve(response.data.user);
    })
    .catch(requestError(dispatch, reject));
});

export const setUserDetails = (username, userDetails) => (dispatch) => new Promise((resolve, reject) => {
  dispatch({ type: IN_PROGRESS });
  axios(getRequestConfig('/user/name/' + username + '/details', userDetails, 'PUT'))
    .then((response) => {
      toast.success('User detail saved');
      dispatch({
        type: SET_USER_DETAILS,
        data: { user: response.data.user },
      });
      resolve(response.data.userDetail);
    })
    .catch(requestError(dispatch, reject));
});

export const getUserList = () => (dispatch) => new Promise((resolve, reject) => {
  dispatch({ type: IN_PROGRESS });
  axios(getRequestConfig('/user/list'))
    .then((response) => {
      dispatch({
        type: GET_USERS,
        data: { users: response.data.users },
      });
      resolve(response.data.users);
    })
    .catch(requestError(dispatch, reject));
});

export const getMessages = (recipientId: string, senderId: string) => (dispatch) => new Promise((resolve, reject) => {
  dispatch({ type: IN_PROGRESS });
  recipientId = recipientId || getUserDetail().id;
  axios(getRequestConfig('/messages?recipientId=' + recipientId + (senderId ? '&senderId=' + senderId : '')))
    .then((response) => {
      dispatch({
        type: GET_MESSAGES,
        data: { messages: response.data.messages },
      });
      resolve(response.data.messages);
    })
    .catch(requestError(dispatch, reject));
});

export const sendMessage = (recipientId: string, subject: string, body: string) => (dispatch) => new Promise((resolve, reject) => {
  dispatch({ type: IN_PROGRESS });
  const data = {
    senderId: getUserDetail().id,
    recipientId,
    subject,
    body,
  };
  axios(getRequestConfig('/messages', data, 'POST'))
    .then((response) => {
      dispatch({ type: COMPLETE });
      resolve(response.data);
    })
    .catch(requestError(dispatch, reject));
});

export const getUserBalanceAndMessages = () => (dispatch) => {
  dispatch({ type: IN_PROGRESS });
  retryRequest(getRequestConfig('/bookmaker/credit_balance'), 'Credit balance fetching failed')
    .then((response) => {
      dispatch({
        type: GET_USER_BALANCE_MESSAGES,
        data: { userBalance: response.data },
      });
    })
    .catch(requestError(dispatch, reject));
};

export const initTrialBalance = () => (dispatch) => {
  dispatch({
    type: INIT_TRIAL_BALANCE
  });
};

export const resetGame = () => (dispatch) => {
  dispatch({
    type: RESET_GAME
  });
};

export const resetTrialGame = () => (dispatch) => {
  dispatch({
    type: RESET_GAME,
    trial: true
  });
};

export const getGameTime = (showLatestResult = false) => (dispatch) => new Promise((resolve, reject) => {
  const requestStartTime = new Date().valueOf();
  dispatch({ type: IN_PROGRESS });
  retryRequest(getRequestConfig('/bookmaker/games?limit=' + (showLatestResult ? 2 : 1)), 'Game session fetching failed')
    .then((response) => {
      const requestEndTime = new Date().valueOf();
      const requestDelay = requestEndTime - requestStartTime;
      const serverTime = new Date(response.data?.serverTime).valueOf();
      const serverTimeDifference = requestEndTime - serverTime;
      const timeDifference = requestDelay / 2 - serverTimeDifference;
      let lastGameSession;

      const gameSession = {
        ...response.data?.sessions[0],
        startTime: moment(response.data?.sessions[0].startedAt).format(TIME_FORMAT),
        serverTime,
        timeDifference,
      } as GameSession;

      if (showLatestResult) {
        lastGameSession = response.data?.sessions[1] ? {
          ...response.data?.sessions[1],
          startTime: moment(response.data?.sessions[1].startedAt).format(TIME_FORMAT),
          serverTime,
          timeDifference,
        } : null;
      }

      dispatch({
        type: GET_GAME_TIME,
        data: {
          session: gameSession,
          lastSession: lastGameSession,
        },
      });

      if (showLatestResult) {
        dispatch({
          type: ALL_RESULT,
          data: lastGameSession
        });
        dispatch({
          type: ALL_SHOW_RESULT_AREA
        });
        dispatch({
          type: ALL_SHOW_WINNING_AREA
        });
      }

      resolve(gameSession);
    })
    .catch(requestError(dispatch, reject));
});

export const getUsers = (userName = null, preventReducer = false) => (dispatch) => new Promise((resolve, reject) => {
  let apiUrl = '/user/balance/list';
  if (userName) {
    apiUrl = `/user/balance/list?filter.name=${userName}`;
  }
  dispatch({ type: IN_PROGRESS });
  axios(getRequestConfig(apiUrl))
    .then((response) => {
      if (response.data.users.length > 0) {
        if (preventReducer) {
          dispatch({ type: COMPLETE });
        } else {
          dispatch({
            type: GET_USERS,
            data: { users: response.data.users },
          });
        }
        resolve(response.data.users);
      } else {
        dispatch({
          type: ERROR,
          data: { error_msg: 'we are not able to find this user' },
        });
        reject('we are not able to find this user');
      }
    })
    .catch(requestError(dispatch, reject));
});

export const creditTransfers = async (transfersDetail) =>
  axios(getRequestConfig('/bookmaker/credit_transfers', transfersDetail, 'POST'));

export const changePassword = (userDetail) => (dispatch) => new Promise((resolve, reject) => {
  dispatch({ type: IN_PROGRESS });
  if (userDetail.oldPassword && userDetail.newPassword) {
    axios(getRequestConfig(`/user/name/${getUserDetail().name}/password/replace`, userDetail, 'POST'))
      .then(() => {
        dispatch({
          type: SUCCESS_MESSAGE,
          data: {
            message: 'Your password is successfully changed',
          },
        });
        resolve(true);
      })
      .catch(requestError(dispatch, reject));
  } else {
    dispatch({
      type: ERROR,
      data: {
        error_msg: 'OldPassword and NewPassword should not be empty',
      },
    });
    reject('OldPassword and NewPassword should not be empty');
  }
});

export const addUser = async (userDetail) =>
  axios(getRequestConfig('/user', userDetail, 'POST'));

export const resetUserTransactions = () => (dispatch) => {
  dispatch({type: IN_PROGRESS});
  dispatch({
    type: RESET_USER_TRANSACTION,
  });
};

export const getUserTransactions = (createdBefore, limit = PAGINATION_LIMIT, userId: string) =>  (dispatch) => new Promise((resolve, reject) => {
  dispatch({ type: IN_PROGRESS });
  const _userId = userId || getUserDetail()?.id;
  const createdQuery = createdBefore ? `&createdBefore=${createdBefore}` : '';
  const path = `/bookmaker/credit_transactions?limit=${limit}&userIds=${_userId}${createdQuery}&labels=transfer`;
  axios(getRequestConfig(path))
    .then((response) => {
      const list = response.data.creditTransactions.filter(c => userId ? c.correspondingUsername === getUserDetail()?.name : true);
      dispatch({
        type: GET_USER_TRANSACTION,
        data: { creditTransactions: list, resetList: !createdBefore },
      });
      resolve(list);
    })
    .catch(requestError(dispatch, reject));
});

export const getUserBets = (createdBefore, _userId) => (dispatch) => new Promise((resolve, reject) => {
  dispatch({ type: IN_PROGRESS });
  const userId = _userId || getUserDetail()?.id;
  const createdQuery = createdBefore ? `&createdBefore=${createdBefore}` : '';
  const path = `/bookmaker/bets?limit=${PAGINATION_LIMIT}&userId=${userId}${createdQuery}&includeProcessed=true`;
  retryRequest(getRequestConfig(path), 'Bet fetching failed')
    .then((response) => {
      dispatch({
        type: GET_USER_BETS,
        data: { betHistory: response.data.bets, resetList: !createdBefore },
      });
      resolve(response.data.bets);
    })
    .catch(requestError(dispatch, reject));
});

export const getUserActiveBets = (userId): Promise<number> => new Promise((resolve, reject) => {
  const path = `/bookmaker/bets?limit=${PAGINATION_LIMIT}&userIds=${userId}`;
  retryRequest(getRequestConfig(path), 'Bet fetching failed')
    .then((response) => {
      if (response.data.bets && response.data.bets.length > 0) {
        const total = response.data.bets?.filter(b => b.userId === userId).reduce((cur, acc) => cur + parseFloat(acc.amount), 0);
        resolve(total);
      } else {
        resolve(0);
      }
    })
    .catch(requestError(null, reject));
});

export const getTopBets = (sessionId: string, gameType: string) => (dispatch) => new Promise((resolve, reject) => {
  dispatch({ type: IN_PROGRESS });
  axios(getRequestConfig(`/bookmaker/games/${sessionId}/top_players?gameType=${gameType}`))
    .then((response) => {
      dispatch({
        type: GET_TOP_BETS,
        data: { topBets: response.data?.players },
      });
      resolve(response.data);
    })
    .catch(requestError(dispatch, reject));
});

export const getTopWinners = (sessionId: string) => (dispatch) => new Promise((resolve, reject) => {
  dispatch({ type: IN_PROGRESS });
  axios(getRequestConfig(`/bookmaker/games/${sessionId}/winnings`))
    .then((response) => {
      dispatch({
        type: GET_TOP_WINNERS,
        data: { topWinners: response.data?.results },
      });
      resolve(response.data);
    })
    .catch(requestError(dispatch, reject));
});


export const getPastResults = (before) => (dispatch) => new Promise((resolve, reject) => {
  dispatch({ type: IN_PROGRESS });
  const path = `/bookmaker/games?limit=${before ? '10' : '11'}${before ? `&createdBefore=${before}` : ''}`;
  retryRequest(getRequestConfig(path), 'Game session fetching failed')
    .then((response) => {
      dispatch({
        type: GET_PAST_RESULTS,
        data: {
          pastResults: response.data.sessions
        },
      });
      resolve(response.data);
    })
    .catch(requestError(dispatch, reject));
});
