import React from 'react';
import Button from '@material-ui/core/Button';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { useParams } from 'react-router-dom';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useTranslation } from 'react-i18next';

import Header from '../../../../Components/Header';
import './Label.scss';
import { FooterMenu } from '../../../../Components/FooterMenu';

const Label = () => {
  const { userName } = useParams();
  const { t } = useTranslation();

  return (
    <div className='agent_layout'>
      <Header name={t('user.label')} />
      <div className='agent_body'>
        <div className='header_box'>
          <span className='sub_header_text'>{userName}</span>
        </div>

        <ValidatorForm
          className='input_form_box'
          autoComplete='off'
        >
          <div className='input_form_box_inner'>
            <div className='input_form'>
              <TextValidator
                validators={['required']}
                errorMessages={['this field is required']}
                placeholder={t('addNewLabel')}
                variant='outlined'
                size='small'
                type='text'
                className='input_box'
              />
            </div>
          </div>
          <div className='form_btn'>
            <Button type='submit' variant='contained' className='submit_btn'>
              {t('label.submit')}
            </Button>
          </div>
        </ValidatorForm>
        <div className='label_list'>
          <div className='label'>
            <span className='label_text'>VIP</span>
            <button className='lm_btn icon'>
              <DeleteOutlineOutlinedIcon />
            </button>
          </div>
          <div className='label'>
            <span className='label_text'>New</span>
            <button className='lm_btn icon'>
              <DeleteOutlineOutlinedIcon />
            </button>
          </div>
          <div className='label'>
            <span className='label_text'>VIP</span>
            <button className='lm_btn icon'>
              <DeleteOutlineOutlinedIcon />
            </button>
          </div>
          <div className='label'>
            <span className='label_text'>New</span>
            <button className='lm_btn icon'>
              <DeleteOutlineOutlinedIcon />
            </button>
          </div>
        </div>
      </div>
      <FooterMenu />
    </div>
  );
};

export default Label;
