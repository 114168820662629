import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useSwipeable } from 'react-swipeable';

import * as UserAction from '../../Store/Actions/User';
import './GamePastResults.scss';
import { DATETIME_FORMAT } from '../../environment.config';
import { useGameReducer } from '../../Store';
import { GameType } from '../../Utils';

interface Props {
  gameType: GameType,
  actions: any,
}

const GamePastResults = (props: Props) => {
  const [sessions, setSessions] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [page, setPage] = useState(0);
  const [scrollUp, setScrollUp] = useState(false);

  const reducer = useGameReducer();

  useEffect(() => {
    initHeaders();

    return () => {
      setSessions([]);
    };
  }, []);

  useEffect(() => {
    setData();
  }, [reducer.pastResults]);

  useEffect(() => {
    fetchBets(page > 0);
  }, [page]);

  const fetchBets = (force = false) => {
    if (!reducer.pastResults[0] || moment(reducer.session.startedAt).diff(reducer.pastResults[0].startedAt, 'ms') !== 0 || force) {
      const results = reducer.pastResults || [];
      props.actions.userAction.getPastResults(page ? results[results.length - 1].startedAt : null);
    }
  };

  const initHeaders = () => {
    switch (props.gameType) {
      case GameType.Baccarat:
        setHeaders([
          t('pastResults.startTime'),
          t('pastResults.player'),
          t('pastResults.banker'),
          t('pastResults.result')
        ]);
        break;
      case GameType.Roulette:
        setHeaders([
          t('pastResults.startTime'),
          t('pastResults.winningNum'),
          t('pastResults.highLow'),
          t('pastResults.redBlack'),
          t('pastResults.evenOdd')
        ]);
        break;
      case GameType.SicBo:
        setHeaders([
          t('pastResults.startTime'),
          t('pastResults.sum'),
          t('pastResults.dice'),
          t('pastResults.size')
        ]);
        break;
    }
  };

  const setData = () => {
    setScrollUp(false);
    const _sessions = [...(reducer.pastResults || [])];
    switch (props.gameType) {
      case GameType.Baccarat:
        setSessions(_sessions.filter(a => a.results.baccarat).slice(0, 10).map(s => [
          moment(s.startedAt).format(DATETIME_FORMAT),
          s.results.baccarat.playerTotal + (s.results.baccarat.multipliers.player_pair ? t('baccaratResult.playerPair.char') : ''),
          s.results.baccarat.bankerTotal + (s.results.baccarat.multipliers.banker_pair ? t('baccaratResult.bankerPair.char') : ''),
          t(`baccaratResult.${s.results.baccarat.winner}`)
        ]));
        break;
      case GameType.Roulette:
        setSessions(_sessions.filter(a => a.results.roulette).slice(0, 10).map(s => [
          moment(s.startedAt).format(DATETIME_FORMAT),
          s.results.roulette.field,
          s.results.roulette.multipliers.field_0 ? '0' : s.results.roulette.multipliers.high ? t('roulette.high') : t('roulette.low'),
          s.results.roulette.multipliers.field_0 ? '0' : s.results.roulette.multipliers.red ? t('roulette.red') : t('roulette.black'),
          s.results.roulette.multipliers.field_0 ? '0' : s.results.roulette.multipliers.odd ? t('roulette.odd') : t('roulette.even'),
        ]));
        break;
      case GameType.SicBo:
        setSessions(_sessions.filter(a => a.results.sicbo).slice(0, 10).map(s => [
          moment(s.startedAt).format(DATETIME_FORMAT),
          s.results.sicbo.dice.reduce((c, a) => (c + a), 0),
          s.results.sicbo.dice.reduce((c, a) => (c + `${c === '' ? '' : ' + '}${a}`), ''),
          s.results.sicbo.multipliers.triple_any
            ? t('sicbo.triple_any')
            : s.results.sicbo.multipliers.range_big ? t('sicbo.range_big') : t('sicbo.range_small'),
        ]));
        break;
    }
  };
  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      switch (eventData.dir) {
        case 'Up':
          if (sessions.length >= 10) { setPage(page => page+1); }
          setScrollUp(true);
          break;
      }
    },
  });

  return (
    <div className='main_layout' id='past_results'>
      <div className='main_layout_inner' {...handlers}>

        <div className='full_table'>
          <div className='table_header'>
            { headers.map(label => (
              <span className='table_header_text' key={label}>{label}</span>
            ))}
          </div>
          <div className={ 'table_body_main ' + (scrollUp ? 'scrollUp ' : '') + (reducer.loading ? 'loading' : '') }>
            { sessions?.map(session => (
              <div className='table_body' key={session[0]}>
                <div className='table_body'>
                  <div className='table_body_list'>
                    { session.map((field, i) => (
                      <span className='body_list' key={i}>{field}</span>
                    )) }
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    userAction: bindActionCreators(UserAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(GamePastResults);

