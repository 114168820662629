import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { useSelector } from 'react-redux';

import { Chip, getDefaultChips, getTopAvailableChip, intToString } from '../../Utils';
import './ChipSlider.scss';
import { AppState } from '../../Store';
import { DEFAULT_TOP_CHIP, TRIAL_BET_LIMIT } from '../../environment.config';

interface Props {
  balance: number;
  activeChips: number;
  bets: number;
  trial?: boolean;
  handleChip: (_: string) => void;
}

export const ChipSlider = (props: Props) => {
  const state = {
    display: true,
  };
  let sliderRef = useRef(null);
  const [lastSelection, setLastSelection] = useState(null);
  const [availableBalance, setAvailableBalance] = useState(0);
  const [slideIndex, setSlideIndex] = useState(0);

  const selectedChip = useSelector((state: AppState) => state.gameReducer.selectedChip);

  useEffect(() => {
    setAvailableBalance(props.balance > props.activeChips
      ? !props.trial
        ? (props.balance - props.activeChips)
        : (TRIAL_BET_LIMIT-props.bets-props.activeChips)
      : 0);
  }, [props.balance, props.bets, props.activeChips]);

  useEffect(() => {
    const selectedChipIndex = chips.findIndex(c => c.id === selectedChip);
    if (selectedChipIndex === -1) { return; }
    // const goToIndex = selectedChipIndex <= 1 ? 0 : (selectedChipIndex - 2);
    const goToIndex = selectedChipIndex;
    if (goToIndex !== slideIndex) {
      (sliderRef as any).slickGoTo(goToIndex);
    }
  }, [selectedChip]);

  useEffect(() => { // check if chip is too small
    setTopChip();
  }, [availableBalance]);

  const setTopChip = () => {
    if (availableBalance >= 5) {
      const topChip = lastSelection || getDefaultChips().find(c => c.id === localStorage.getItem('lastChip'))?.id || DEFAULT_TOP_CHIP;
      applyChip(getTopAvailableChip(chips.filter(c => c.value <= topChip), availableBalance));
    } else if (!!selectedChip && availableBalance < parseInt(selectedChip) && props.activeChips) {
      applyChip(getTopAvailableChip(chips, availableBalance));
    }
  };

  const handleChipClick = (chip: string) => {
    setLastSelection(chip);
    applyChip(chip);
    localStorage.setItem('lastChip', chip);
  };

  const applyChip = (chip: string) => {
    props.handleChip(chip);
  };

  const selectIndex = (_, leftChipIndex: number) => {
    setSlideIndex(leftChipIndex);
  };

  const settings = {
    dots: true,
    arrows: false,
    centerMode: true,
    centerPadding: '0',
    infinite: false,
    speed: 200,
    swipe: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    beforeChange: selectIndex,
  };

  const [chips] = useState<Chip[]>(getDefaultChips());

  return (
    <div
      className='slider_main'
      style={{
        display: state.display ? 'block' : 'none',
      }}
    >
      <Slider {...settings} ref={slider => {sliderRef = slider;}}>
        {chips.map((chip) => (
          <div
            key={chip.index}
            className={`chip ${chip.borderColor}
              ${ chip.id === selectedChip ? 'selected-chip' : '' } 
              ${ chip.value > availableBalance ? 'disabled' : '' } 
              ${ chip.id.length > 3 ? 'long-text' : '' } 
            `}
            onClick={() => {
              if (chip.value <= availableBalance) {
                handleChipClick(chip.id);
              }
            }}
          >
            <span>{intToString(chip.value)}</span>
          </div>
        ))}
      </Slider>
    </div>
  );
};
