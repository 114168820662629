import React from 'redux';
import { useNavigate } from 'react-router-dom';

import './StartPages.scss';

interface props {
  isManager?: boolean;
}

export const StartPages = (props: props) => {
  const navigate = useNavigate();
  const baseUrl = props.isManager ? '/Manager' : '/Agent';

  const redirect = (url) => {
    navigate(url);
  };

  return (
    <div className='pages_widget'>
      <button className="nav_button player"  onClick={() => redirect(baseUrl + '/Users')}>
        <span className="icon"></span>
        { props.isManager && (
          <>
            <span className="cn">玩家</span>
            <span className="en">Agents</span>
          </>
        )}
        { !props.isManager && (
          <>
            <span className="cn">玩家</span>
            <span className="en">Players</span>
          </>
        )}
      </button>
      <button className="nav_button add_player" onClick={() => redirect(baseUrl + '/Users/Add')}>
        <span className="icon"></span>
        { props.isManager && (
          <>
            <span className="cn">添加玩家</span>
            <span className="en">Add Agent</span>
          </>
        )}
        {!props.isManager && (
          <>
            <span className="cn">添加玩家</span>
            <span className="en">Add Player</span>
          </>
        )}
      </button>
      <button className="nav_button message" onClick={() => redirect(baseUrl + '/Messages')}>
        <span className="icon"></span>
        <span className="cn">信息</span>
        <span className="en">Message</span>
      </button>
      <button className="nav_button transaction" onClick={() => redirect(baseUrl + '/Transactions')}>
        <span className="icon"></span>
        <span className="cn">交易记录</span>
        <span className="en">Transaction</span>
      </button>
    </div>
  );
};
