import React from 'react';
import './TopWinners.scss';
import { useAutoScroll } from '../useAutoScroll';
import { useGameReducer } from '../../../Store';
import { roundBalance } from '../../../Utils';

interface Props {
  topWinners: any[];
}

const TopBetsDialog = (props: Props) => {
  useAutoScroll('list_auto_scroll');

  return (
    <div className='games_winners_list horizontal_list_dialog top_dialog_box'>
      <div className='list_inner list_title'>
        <div className="icon"></div>
      </div>
      <div className='list_main list_auto_scroll'>
        {props.topWinners.map((e, i) => {
          return (
            <div key={i} className='list_inner'>
              <div className='players_amount'>
                <span className='player'>{ e.playerDisplayName }</span>
              </div>
              <div className='players_amount'>
                <span className='amount'>{ roundBalance(e.amount) }</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};



const TopPayout = () => {
  const reducer = useGameReducer();

  return (
    <>
      {reducer.topWinners && reducer.topWinners.length > 0 && (
        <TopBetsDialog topWinners={reducer.topWinners} />
      )}
    </>
  );
};

export default TopPayout;
