import React from 'react';
import { useParams } from 'react-router-dom';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

import { useUser } from '../../../Store';

export const PlayerReportSearch = () => {
  const user = useUser(useParams().userId);
  const { t } = useTranslation();

  return (
    <div className='agent_body'>
      <div className='header_box'>
        <span className='row_details_text'>{user.user.name}</span>
      </div>
      <ValidatorForm
        className='input_form_box'
        autoComplete='off'
      >
        <div className='input_form_box_inner'>
          <div className='input_form'>
            <span className='input_box_text'>{t('report.startDate')}</span>
            <TextValidator
              validators={['required']}
              errorMessages={[t('error.fieldRequired')]}
              variant='outlined'
              size='small'
              type='date'
              className='input_box'
            />
          </div>
          <div className='input_form'>
            <span className='input_box_text'>{t('report.endDate')}</span>
            <TextValidator
              validators={['required']}
              errorMessages={[t('error.fieldRequired')]}
              variant='outlined'
              size='small'
              type='date'
              className='input_box'
            />
          </div>

          <div className='input_form'>
            <span className='input_box_text black'>{t('report.numberOfDays')} = 7</span>
          </div>

          <div className='input_form'>
            <span className='input_box_text'>{t('report.label')}</span>
            <TextValidator
              variant='outlined'
              size='small'
              type='text'
              className='input_box'
            />
          </div>
        </div>
        <div className='form_btn'>
          <Button type='submit' variant='contained' className='submit_btn'>
            {t('label.submit')}
          </Button>
        </div>
      </ValidatorForm>
    </div>
  );
};
